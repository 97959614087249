import React, { useState, useEffect, createRef } from 'react';
import { MdFileUpload } from "react-icons/md";
import { TextField, InputLabel, IconButton } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import { fetchToAPI, updatePhoto } from '../commonItems/Fetch';
import LocalStorageService from '../../Services/LocalStorageService';
import Modal from 'react-bootstrap/Modal';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';

const resiliencyValidation = yup.object().shape({
    name: yup.string()
        .label('Resiliency name')
        .required(),
});


export default function ResiliencyPage(props) {
    const id = props.match.params.id;
    const [subject, setSubject] = useState(null);
    const [subjectToEdit, setSubjectToEdit] = useState(null);
    const [pdfToEdit, setPdfToEdit] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [titleToEdit, setTitleToEdit] = useState('');
    const [pdf, setPDF] = useState();
    const [photo, setPhoto] = useState('');
    const [photoToEdit, setPhotoToEdit] = useState('');
    const [photoToDelete, setPhotoToDelete] = useState(false);
    const title = props.match.params.title;
    const dropzoneRef = createRef();
    const [bearerToken, setBearerToken] = useState();

    useEffect(() => {
        setBearerToken(LocalStorageService.fetchToken());
    }, [])

    const getSubject = async () => {
        const result = await fetchToAPI(`https://app.toiyabe.us/api/admin/trauma/${id}`);
        setSubject(result.data)
    }

    useEffect(() => {
        getSubject();
    }, []);

    const handleNewAddition = async (values) => {
        const body = new FormData();
        body.append("name", values.name);
        if(photo) {
            body.append("photo", photo.file);
        }

        if(pdf) {
            body.append("pdf", pdf);
        }

        body.append("featured", 1);
        const result = await updatePhoto(`https://app.toiyabe.us/api/admin/trauma/${id}/subject`, body);
        setPhoto(null);
        setPDF(null);
        setOpen(false);
        getSubject();
    };

    const handleEdit = async (values) => {
        const bodyFormData = new FormData();
        bodyFormData.append("name", values.name);
        console.log('Handle Edit - photoToDelete', photoToDelete);

        if(photoToDelete) {
            bodyFormData.append('deletephoto', 1);
        } else if(photoToEdit && photoToEdit.file) {
            bodyFormData.append('photo', photoToEdit.file);
        }

        if(!pdfToEdit) {
            bodyFormData.append('deletepdf', 1);
        } else if (pdfToEdit && pdfToEdit.file){
            bodyFormData.append('pdf', pdfToEdit.file);
        }

        bodyFormData.append("featured", 1);

        await updatePhoto(`https://app.toiyabe.us/api/admin/trauma/${id}/subject/${subjectToEdit.id}`, bodyFormData);

        getSubject();
        setSubjectToEdit(null);
        setPhoto(null);
        setPhotoToDelete(false);
        setPDF(null);
        setOpenEdit(false);
    };

    const onImageChange = async (event, type) => {
        if (event.target.files && event.target.files[0]) {
            if(id){
                const fr = new FileReader();
                const file = event.target.files[0];
                fr.onload = (ev) => {
                    if(type == 'add') {
                        setPhoto({url: fr.result, file: file}); 
                    } else {
                        setPhotoToEdit({url: fr.result, file: file}); 
                    }
                };
                fr.readAsDataURL(event.target.files[0]);
            }
        }
    }

    const getSubjectToEdit = async (obj) => {
        setSubjectToEdit(obj);
        setTitleToEdit(obj.name);
        setPdfToEdit(obj.pdf ? {url: obj.pdf} : null);
        setPhotoToEdit(obj.photo ? {url: obj.photo} : null);
        setPhotoToDelete(false);
        setOpenEdit(true);
    };

    const handleDelete = async () => {
        const body = new FormData();
        body.append("_method", "delete");

        let subId = subjectToEdit.id;
        const result = await updatePhoto(`https://app.toiyabe.us/api/admin/trauma/${id}/subject/${subId}`, body);
        getSubject();
        setSubjectToEdit(null);
        setPhoto(null);
        setPhotoToDelete(null);
        setPDF(null);
        setOpenEdit(false);
    }

    return (
        <div className="page">            
            <div className="resiliancyHeader">
                <h3 style={{fontSize: '30px'}}>{title}</h3>
                <button onClick={() => setOpen(true)} 
                style={{    
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                    backgroundColor: '#3B7D7D',
                }}>
                    Add Subject +
                </button>
            </div>
            <div className="resiliancyDescription">
                <h5 style={{fontSize: '12pt'}}>{title} Description</h5>
                <p style={{fontSize: '12pt'}}>{subject?.description ?? ''}</p>
            </div>
            <div className="resiliancy-table">
               <table className="table table-borderless">
                    {subject?.trauma_subjects && subject.trauma_subjects.map((obj) => {
                        return (
                            <tr key={obj.name}>
                                <td>
                                    <div style={{fontSize: '12pt', cursor: 'pointer'}} onClick={() => getSubjectToEdit(obj)}>
                                        {obj.name}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>
            {/* Add Resiliency */}
            {open && (
                <Formik
                initialValues={{
                    name: '',
                }}
                onSubmit={(values) => handleNewAddition(values)}
                validationSchema={resiliencyValidation}>
                {(formikProps) => (
                    <Modal
                    show={open}
                    onHide={() => { formikProps.resetForm(); setOpen(false)}}
                    backdrop="static"
                    keyboard={false}
                    >
                        <form key="addResilencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                        <Modal.Header closeButton>
                        <Modal.Title>Add Resiliency</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div style={{flexDirection: 'row'}}>
                            <div style={{paddingTop: '16px' }}>
                                <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                                >
                                    Resiliency Name
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('name')}
                                    onBlur={formikProps.handleBlur('name')}
                                    value={formikProps.values.name}
                                    size="small"
                                    style={{ 
                                        width: '100%', 
                                        position: 'relative',
                                    }}
                                    placeholder="Enter resiliency name"
                                />
                                <ErrorMessage name="name" component="div" className="text-danger" />
                            </div>
                            <div style={{marginTop: '16px'}}>
                            <Dropzone ref={dropzoneRef} noClick noKeyboard>
                                {({getRootProps, getInputProps, acceptedFiles}) => {
                                    return (
                                    <div className="container">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        {pdf ? 
                                            <div>
                                            <p style={{}}>{pdf.name}</p>
                                            <button type="button" className="delete-word-button" onClick={() => { setPDF(null);}}>
                                                Remove PDF
                                            </button>
                                            </div>
                                        : 
                                            <button type="button" onClick={() => document.getElementById('upload-file-add').click()} className="delete-word-button">
                                                Add Document (pdf)
                                            </button>
                                        }
                                        <input type='file' accept="application/pdf" hidden name="file" id="upload-file-add" onChange={(e) => {
                                            setPDF(e.target.files[0]); 
                                            const fr = new FileReader();
                                            fr.readAsDataURL(e.target.files[0]);
                                            }}
                                        />
                                        </div>
                                    </div>
                                    );
                                }}
                            </Dropzone>
                            </div>
                            <div style={{marginTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14 , fontWeight: 'bold',}}
                            >
                                Add Photo/Illustration
                            </InputLabel>
                            <Dropzone ref={dropzoneRef} noClick noKeyboard>
                                {({getRootProps, getInputProps, acceptedFiles}) => {
                                    return (
                                    <div className="container">
                                        <div style={{marginBottom: '10px'}}{...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        {photo ?
                                            <div> 
                                            <img 
                                            style={{
                                                width: '9.7rem',
                                                height: '9.75rem',
                                            }}
                                            src={photo.url ? photo.url : photo.file }
                                            />
                                            <button type="button" className="delete-word-button" onClick={() => { setPhoto(null);}}>
                                                Remove Photo
                                            </button>
                                            </div>
                                        : 
                                            <IconButton onClick={() => document.getElementById('upload-image-add').click()}  style={{border: '52px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                                <MdFileUpload onClick={(e) => onImageChange(e, 'add')} color="#FFF" />
                                            </IconButton>
                                        }
                                        </div>
                                        <input type='file' accept="image/*" hidden name="image" id="upload-image-add" onChange={(e) => {
                                            const fr = new FileReader();
                                            const file = e.target.files[0];
                                            fr.onload = (ev) => {
                                                setPhoto({url: fr.result, file: file}); 
                                            };
                                            fr.readAsDataURL(e.target.files[0]);
                                            }}
                                        />
                                    </div>
                                    );
                                }}
                            </Dropzone>
                            </div>
                        </div>
                        </Modal.Body>
                        <Modal.Footer style={{justifyContent: 'center'}} onClick={formikProps.handleSubmit}>
                            <button type="button" style={{width: '340px', }} className="add-tribe-button">
                                Save Resiliency
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                )}
                </Formik>
            )}
            {/* Edit Resiliency */}
            {openEdit && (
                <Formik
                initialValues={{
                    name: titleToEdit,
                }}
                onSubmit={(values) => handleEdit(values)}
                validationSchema={resiliencyValidation}>
                {(formikProps) => (
                    <Modal
                    show={openEdit}
                    onHide={() => { formikProps.resetForm(); setOpenEdit(false)}}
                    backdrop="static"
                    keyboard={false}
                    >
                        <form key="addResilencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                        <Modal.Header closeButton>
                        <Modal.Title>Edit Resiliency</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div style={{flexDirection: 'row'}}>
                            <div style={{paddingTop: '16px' }}>
                                <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                                >
                                    Resiliency Name
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('name')}
                                    onBlur={formikProps.handleBlur('name')}
                                    value={formikProps.values.name}
                                    size="small"
                                    style={{ 
                                        width: '100%', 
                                        position: 'relative',
                                    }}
                                    placeholder="Enter resiliency name"
                                />
                                <ErrorMessage name="name" component="div" className="text-danger" />
                            </div>
                            <div style={{marginTop: '16px'}}>
                            <Dropzone ref={dropzoneRef} noClick noKeyboard>
                                {({getRootProps, getInputProps, acceptedFiles}) => {
                                    return (
                                    <div className="container">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        {pdfToEdit ? 
                                            <div>
                                            <p style={{}}>{pdfToEdit.name ? pdfToEdit.name : pdfToEdit.url}</p>
                                            <button type="button" className="delete-word-button" onClick={() => { setPdfToEdit(null);}}>
                                                Remove PDF
                                            </button>
                                            </div>
                                        : 
                                            <button type="button" onClick={() => document.getElementById('upload-file-edit').click()} className="delete-word-button">
                                                Add Document (pdf)
                                            </button> 
                                        }
                                        <input type='file' accept="application/pdf" hidden name="file" id="upload-file-edit" onChange={(e) => {
                                            setPdfToEdit(e.target.files[0]); 
                                            const fr = new FileReader();
                                            fr.readAsDataURL(e.target.files[0]);
                                            }}
                                        />
                                        </div>
                                    </div>
                                    );
                                }}
                            </Dropzone>
                            </div>
                            <div style={{marginTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14 , fontWeight: 'bold',}}
                            >
                                Add Photo/Illustration
                            </InputLabel>
                            <Dropzone ref={dropzoneRef} noClick noKeyboard>
                                {({getRootProps, getInputProps, acceptedFiles}) => {
                                    return (
                                    <div className="container">
                                        <div style={{marginBottom: '10px'}}{...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        {photoToEdit ? 
                                            <div>
                                            <img 
                                            style={{
                                                width: '9.7rem',
                                                height: '9.75rem',
                                            }}
                                            src={photoToEdit.url ? photoToEdit.url : photoToEdit.file }
                                            />
                                            <button type="button" className="delete-word-button" onClick={() => { setPhotoToEdit(null); setPhotoToDelete(true);}}>
                                                Remove Photo
                                            </button>
                                            </div>
                                        : 
                                            <IconButton onClick={() => document.getElementById('upload-image-edit').click()}  style={{border: '52px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                                <MdFileUpload onClick={(e) => onImageChange(e, 'edit')} color="#FFF" />
                                            </IconButton>
                                        }
                                        </div>
                                        <input type='file' accept="image/*" hidden name="image" id="upload-image-edit" onChange={(e) => {
                                            const fr = new FileReader();
                                            const file = e.target.files[0];
                                            fr.onload = (ev) => {
                                                setPhotoToEdit({url: fr.result, file: file}); 
                                            };
                                            fr.readAsDataURL(e.target.files[0]);
                                            }}
                                        />
                                    </div>
                                    );
                                }}
                            </Dropzone>
                            </div>
                        </div>
                        </Modal.Body>
                        <Modal.Footer style={{justifyContent: 'center'}} onClick={formikProps.handleSubmit}>
                            <button type="button" style={{width: '340px', }} className="add-tribe-button">
                                Save Resiliency
                            </button>
                            <button type="button" style={{width: '340px', }} className="delete-tribe-button" onClick={handleDelete}>
                                Delete Resiliency
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                )}
                </Formik>
            )}
        </div>
    )
}
