import React from 'react';
import './NotFound.css'

export default function NotFound(){
    return (
        <>
        <div className="notFoundContainer">
            <h1 className="header" style={{fontWeight: 300, fontFamily: 'sans-serif'}}>404 - Page not found.</h1>
            <h3 className="header" style={{fontWeight: 100, fontFamily: 'sans-serif'}}>The page you are looking for has changed or been removed. Please check the address and try again.</h3>
        </div>
        </>
    )
}