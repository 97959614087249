import React, { useState, useEffect, forwardRef } from 'react'
import CreateIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import { TextField, InputLabel, } from '@material-ui/core'
import { fetchToAPI, postApplicationJson, deleteItem, putApplicationJson } from '../commonItems/Fetch';
import './Admin.css';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';

const newAdminValidation = yup.object().shape({
    username: yup.string()
        .required()
        .label('Username'),
    email: yup.string()
        .required()
        .label('Email Address'),
    password: yup.string()
        .required()
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special character")
        .label('Password'),
    confirmation: yup.string()
        .required()
        .label('Confirm')
        .oneOf([yup.ref('password')], 'Password doesn’t match'),
    level: yup.string()
        .label('Permission Level'),
});

const editAdminValidation = yup.object().shape({
    username: yup.string()
        .required()
        .label('Username'),
    email: yup.string()
        .required()
        .label('Email Address'),
    level: yup.string()
        .label('Permission Level'),
});

export default function AdminsHome() { 
    const [admins, setAdmins] = useState();
    const [open, setOpen] = React.useState(false);
    const [emailToEdit, setEmailToEdit] = useState(null);
    const [usernameToEdit, setUsernameToEdit] = useState(null);
    const [usernameToDelete, setUsernameToDelete] = useState(null);
    const [idToDelete, setIdToDelete] = useState(null);
    const [idToEdit, setIdToEdit] = useState(0);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [passwordResetting, setPasswordResetting] = useState(false);
    const [newPassword, setNewPassword] = useState(null);
    const [newConfirmationPassword, setNewConfirmationPassword] = useState(null);
    const [newPasswordError, setNewPasswordError] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const editUser = (user, id) => {
        setEmailToEdit(user.email);
        setUsernameToEdit(user.username ?? '');
        setIdToEdit(id);
        setOpenEdit(true);
    };

    const handleAddAdmin = async (values) => {
        let result = await postApplicationJson(`https://app.toiyabe.us/api/admin/user`, {
            username: values.username,
            email: values.email,
            password: values.password,
            admin: 1
        });
        alert(result.data.message);
        getAdmins();
        setOpen(false);
    };

    const handleEditAdmin = async (values) => {
        let result = await putApplicationJson(`https://app.toiyabe.us/api/admin/user/${idToEdit}`, {
            username: values.username,
            email: values.email,
            admin: 1,
        });
        alert(result.data.message);
        getAdmins();
        setIdToEdit(null);
        setEmailToEdit(null);
        setOpenEdit(false)
    };

    const handleSelectDeleteUser = async (id, username) => {
        setIdToDelete(id);
        setUsernameToDelete(username);
        setOpenDelete(true);
    }

    const deleteUser = async () => {
        let result = await deleteItem(`https://app.toiyabe.us/api/admin/user/${idToDelete}`);
        setIdToDelete(null);
        setUsernameToDelete(null);
        getAdmins();
        setOpenDelete(false);
    };
    
    const getAdmins = async () => {
        const results = await fetchToAPI('https://app.toiyabe.us/api/admin/user');
        setAdmins(results.data)
    };

    const requestPasswordReset = async () => {
        await postApplicationJson(`https://app.toiyabe.us/api/admin/user/forgot`, {
            email: emailToEdit,
        });
        alert("A password reset email has been sent successfully.");
    }

    useEffect(() => {
        getAdmins();
    }, []);

    return (
        <div className="page">
            <div className="resiliancyHeader">
                <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>Admins</h3>
                <button onClick={() => handleOpen(true)} 
                style={{    
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                }}>
                    Add User +
                </button>
            </div>

            {admins ? 
            <div className="admin-table">
                <div className="table table-borderless container-fluid" style={{flex: 1}}>
                    <div className="border-bottom row">
                        <div className="col-md-4" style={{fontWeight: 'bold', paddingBottom: '8px'}}>Username</div>
                        <div className="col-md-4" style={{fontWeight: 'bold', paddingBottom: '8px'}}>Email Address</div>
                    </div>
                    <div>
                        {admins.map((obj) => {
                            return (
                                <div className="row" key={obj.email} style={{display: 'flex', padding: '16px', marginBottom: '16px', alignItems: 'center', backgroundColor: '#FFF'}}>
                                    <div className="col-md-4">{obj.username}</div>
                                    <div className="col-md-4">{obj.email}</div>
                                    <div className="col-md-4">
                                        <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-end'}}>
                                            <CreateIcon 
                                                style={{ color: 'black', marginRight: '16px', fontSize: 30, cursor: 'pointer', }}
                                                onClick={() => editUser(obj, obj.id)}
                                            />
                                            <DeleteOutlined
                                            style={{ color: 'black', fontSize: 30, cursor: 'pointer', }}
                                            onClick={() => handleSelectDeleteUser(obj.id, obj.username)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            :
                null
            }
            {open ? 
                <Formik
                initialValues={{
                    username: '',
                    email: '',
                    password: '',
                    confirmation: '',
                    level: 1,
                }}
                onSubmit={(values) => handleAddAdmin(values)}
                validationSchema={newAdminValidation}>
                {(formikProps) => (
                <Modal
                show={open}
                onHide={() => setOpen(false)}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addWordForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Admin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Username
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('username')}
                                onBlur={formikProps.handleBlur('username')}
                                value={formikProps.values.username}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter username"
                            />
                            <ErrorMessage name="username" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Email Address
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('email')}
                                onBlur={formikProps.handleBlur('email')}
                                value={formikProps.values.email}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter email address"
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Create Password
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('password')}
                                onBlur={formikProps.handleBlur('password')}
                                value={formikProps.values.password}
                                size="small"
                                type="password"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter password"
                            />
                            <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Confirm
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('confirmation')}
                                onBlur={formikProps.handleBlur('confirmation')}
                                value={formikProps.values.confirmation}
                                type="password"
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Retype password"
                            />
                            <ErrorMessage name="confirmation" component="div" className="text-danger" />
                            <p style={{position: 'relative', display: 'flex', justifyContent: 'center', fontSize: '12pt'}}>
                                Password must contain 8+ characters, 1 uppercase letter, 1 lowercase lettter, 1 number, and 1 special symbol.
                            </p>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Add User
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
            {openEdit ? 
                <Formik
                initialValues={{
                    username: usernameToEdit,
                    email: emailToEdit,
                }}
                onSubmit={(values) => handleEditAdmin(values)}
                validationSchema={editAdminValidation}>
                {(formikProps) => (
                <Modal
                show={openEdit}
                onHide={() => setOpenEdit(false)}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addWordForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Manage Admin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Username
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('username')}
                                onBlur={formikProps.handleBlur('username')}
                                value={formikProps.values.username}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter username"
                            />
                            <ErrorMessage name="username" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Email Address
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('email')}
                                onBlur={formikProps.handleBlur('email')}
                                value={formikProps.values.email}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter email address"
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                        
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <button type="button" style={{width: '340px', alignSelf: 'center' }} className="delete-word-button" onClick={requestPasswordReset}>
                                Send Password Reset URL
                            </button>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Save
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
            {openDelete ? 
                <Modal
                show={setOpenDelete}
                onHide={() => setOpenDelete(false)}
                backdrop="static"
                keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion of User {usernameToDelete}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px'}}>
                            <p>
                                Warning: This action cannot be undone!
                            </p>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <div style={{flexDirection:'row', justifyContent: 'space-between'}}>
                            <button type="button" style={{width: '150px', marginRight: '32px' }} className="delete-word-button" onClick={deleteUser}>
                                Delete User
                            </button>
                            <button type="button" style={{width: '150px', }} className="add-word-button" onClick={() => setOpenDelete(false)}>
                                Cancel
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            :
                null
            }
            
            
        </div>
    )
}
