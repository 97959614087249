import React, { useState, useEffect, createRef } from 'react'
import { MdFileUpload } from "react-icons/md";
import InputBase from '@material-ui/core/InputBase';
import { TextField, InputLabel, IconButton } from '@material-ui/core'
import './CulturePage.css'
import { updateCulture, fetchToAPI, addCultureResource, deleteItem, updatePhoto } from '../commonItems/Fetch';
import ReactPlayer from 'react-player'
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import LocalStorageService from '../../Services/LocalStorageService';
import Modal from 'react-bootstrap/Modal';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import ReactAudioPlayer from 'react-audio-player';
import Constants from '../../Constants';

const wordValidation = yup.object().shape({
    word: yup.string()
        .label('Word/Phrase')
        .required(),
    definition: yup.string()
        .label('Meaning')
        .required(),
    audio: yup.string()
        .label('Add Audio'),
    photo: yup.string()
        .label('Add Photo'),
});

const courseValidation = yup.object().shape({
    title: yup.string()
        .label('Title')
        .required(),
    description: yup.string()
        .label('Description')
        .required(),
    location: yup.string()
        .label('Location')
        .required(),
    eventDate: yup.string()
        .label('Event Date')
        .required(),
    eventStart: yup.string()
        .label('Event Start Time')
        .required(),
    eventEnd: yup.string()
        .label('Event End Time')
        .required(),
});

//HAVE THE PROPS BE A TRIBE OBJECT 
export default function CulturePage(props) {
    const id = props.match.params.id;
    const [culture, setCulture] = useState();
    const [description, setDescription] = useState();
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openCourse, setOpenCourse] = useState(false);
    const [photo, setPhoto] = useState('');
    const [photoToAdd, setPhotoToAdd] = useState('');
    const [audioToAdd, setAudio] = useState();
    const [audioToAddFileName, setAudioFileName] = useState(null);
    const dropzoneRef = createRef();
    const [controls, setControls] = useState({
        playing: false,
        url: '',
        controls: false
    })
    const [newTribeName, setNewTribeName] = useState();
    const [photoToEdit, setPhotoToEdit] = useState(null);
    const [audioToEdit, setAudioToEdit] = useState(null);
    const [wordToEdit, setWordToEdit] = useState(null);
    const [meaningToEdit, setMeaningToEdit] = useState(null);
    const [wordIDToEdit, setWordIDToEdit] = useState(null);
    const [cultureToEdit, setCultureToEdit] = useState(null);
    const [cultureIDToEdit, setCultureIDToEdit] = useState(null);
    const [newCultureVideo, setNewCultureVideo] = useState(null);
    const [newCulturePhoto, setNewCulturePhoto] = useState(null);
    const [newCulturePDF, setNewCulturePDF] = useState(null);
    const [editCultureVideo, setEditCultureVideo] = useState(null);
    const [editCultureTitle, setEditCultureTitle] = useState(null);
    const [editCultureDescription, setEditCultureDescription] = useState(null);
    const [editCulturePhoto, setEditCulturePhoto] = useState(null);
    const [editCulturePDF, setEditCulturePDF] = useState(null);
    const [editCultureLocation, setEditCultureLocation] = useState(null);
    const [editCultureDate, setEditCultureDate] = useState(null);
    const [editCultureStartTime, setEditCultureStartTime] = useState(null);
    const [editCultureEndTime, setEditCultureEndTime] = useState(null);
    const [editCourse, setEditCourse] = useState(false);
    const [bearerToken, setBearerToken] = useState();

    useEffect(() => {
        setBearerToken(LocalStorageService.fetchToken());
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        // Add Word
        setOpen(false);
        setPhotoToAdd(null);
        setAudio(null);

        setOpenEdit(false)
        setWordToEdit(null)
        setMeaningToEdit(null)
        setPhotoToEdit(null)
        setAudioToEdit(null)

        // Add Course
        setOpenCourse(false)
        setNewCultureVideo(null);
        setNewCulturePhoto(null);
        setNewCulturePDF(null);

        setEditCourse(false);
        setCultureToEdit(null);
        setCultureIDToEdit(null);
        setEditCultureTitle(null);
        setEditCultureDescription(null);
        setEditCultureVideo(null);
        setEditCulturePhoto(null);
        setEditCulturePDF(null)
        setEditCultureLocation(null)
        setEditCultureDate(null);
        setEditCultureStartTime(null);
        setEditCultureEndTime(null);
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const ph = event.target.files[0];
            const body = new FormData();
            body.append("name", newTribeName);
            body.append("description", description);
            body.append("deletephoto", 0);
            body.append("_method", "PATCH");
            body.append("photo", ph);
            async function updateCulturePhoto(){
              const result = await updatePhoto(`https://app.toiyabe.us/api/admin/culture/${id}`, body);
              alert(result.data.message);
            }
            updateCulturePhoto();
        }
    };

    const getCulture = async () => {
        let results = await fetchToAPI(`https://app.toiyabe.us/api/admin/culture/${id}`);
        results = results.data;
        setCulture(results);
        setNewTribeName(results.name);
        setDescription(results.description);
        setPhoto(results.photo);
    };

    const getCultureWordToEdit = async (id) => {   
        setWordIDToEdit(id);   
        setOpenEdit(true);
        const word = culture.culture_words.filter(obj => obj.id === id);
        setPhotoToEdit(word[0].photo ? {url:word[0].photo, file: null} : null);
        setAudioToEdit(word[0].audio ? {url: word[0].audio, file: null}: null);
        setWordToEdit(word[0].phrase);
        setMeaningToEdit(word[0].meaning);
    };

    const updateTribeName = async () => {
        await updateCulture(`https://app.toiyabe.us/api/admin/culture/${id}`, {
            name: newTribeName,
            description: description,
            deletephoto: 0
        })
    };

    const updateTribeDescription = async () => {
        await updateCulture(`https://app.toiyabe.us/api/admin/culture/${id}`, {
            description: description,
            name: newTribeName,
            deletephoto: 0
        })
    };

    const handleDeletePhoto = async () => {
        await updateCulture(`https://app.toiyabe.us/api/admin/culture/${culture.id}`, {
            description: description,
            name: newTribeName,    
            deletephoto: 1           
        })
    };

    const handleAddWord = async (values) => {
        let bodyFormData = new FormData();
        bodyFormData.append('phrase', values.word);
        bodyFormData.append('meaning', values.definition);
        if(audioToAdd) {
            bodyFormData.append('audio', audioToAdd.file);
        }
        if(photoToAdd) {
            bodyFormData.append('photo', photoToAdd.file);
        }
        //add new word to culture's culture_words
        await addCultureResource(
            `https://app.toiyabe.us/api/admin/culture/${culture.id}/word`, 
            bodyFormData
        );
        getCulture();
        handleClose();
    };

    const handleEditWord = async (values) => {
        let bodyFormData = new FormData();
        bodyFormData.append('_method', 'put');
        bodyFormData.append('phrase', values.word);
        bodyFormData.append('meaning', values.definition);

        if(!audioToEdit) {
            bodyFormData.append('deleteaudio', 1);
        } else if(audioToEdit && audioToEdit.file) {
            bodyFormData.append('audio', audioToEdit.file);
        }

        if(!photoToEdit) {
            bodyFormData.append('deletephoto', 1);
        } else if (photoToEdit && photoToEdit.file){
            bodyFormData.append('photo', photoToEdit.file);
        }

        await addCultureResource(`https://app.toiyabe.us/api/admin/culture/cultureword/${wordIDToEdit}`, bodyFormData);

        getCulture();
        handleClose();
    };

    const deleteWord = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/culture/cultureword/${wordIDToEdit}`);
        setOpenEdit(false);
        getCulture();
    };

    const handleAddCourse = async (values) => {
        const body = new FormData();
        body.append("title", values.title);
        body.append("description", values.description);
        if(newCulturePhoto) {
            body.append("photo", newCulturePhoto.file);
        }
        body.append("location", values.location);
        body.append("event_date", values.eventDate);
        body.append("event_start_time", moment(values.eventStart, 'HH:mm').format('HH:mm:ss'));
        body.append("event_end_time", moment(values.eventEnd, 'HH:mm').format('HH:mm:ss'));
        await addCultureResource(`https://app.toiyabe.us/api/admin/culture/${culture.id}/course`, body);
        getCulture();
        handleClose();
    };

    const handleCultureToEdit = (obj) => {
        setEditCourse(true);
        setCultureToEdit(obj);
        setCultureIDToEdit(obj.id);
        setEditCultureTitle(obj.title);
        setEditCultureDescription(obj.description);
        setEditCultureVideo(obj.video ? {url: obj.video, file: null} : null);
        setEditCulturePhoto(obj.photo ? {url: obj.photo, file: null} : null);
        setEditCulturePDF(obj.pdf ? {url: obj.pdf, file: null} : null);
        setEditCultureLocation(obj.location)
        setEditCultureDate(obj.event_date);
        setEditCultureStartTime(moment(obj.event_start_time, 'HH:mm:ss').format('HH:mm'));
        setEditCultureEndTime(moment(obj.event_end_time, 'HH:mm:ss').format('HH:mm'));
    };

    const handleEditCulture = async (values) => {
        const body = new FormData();
        body.append("title", values.title);
        body.append("description", values.description);
        body.append("deletevideo", 0);
        body.append("deletepdf", 0);

        if(cultureToEdit.photo && !editCulturePhoto) {
            body.append("deletephoto", 1);
        } else if(editCulturePhoto && editCulturePhoto.file) {
            body.append("photo", editCulturePhoto.file);
            body.append("deletephoto", 0);
        } else {
            body.append("deletephoto", 0);
        }

        body.append("location", values.location);
        body.append("event_date", values.eventDate);
        body.append("event_start_time", moment(values.eventStart, 'HH:mm').format('HH:mm:ss'));
        body.append("event_end_time", moment(values.eventEnd, 'HH:mm').format('HH:mm:ss'));

        body.append("_method", "put");
        await updatePhoto(`https://app.toiyabe.us/api/admin/culture/culturecourse/${cultureIDToEdit}`, body);
        handleClose();
        getCulture();
    };
    
    const deleteCourse = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/culture/culturecourse/${cultureIDToEdit}`);
        handleClose();
        getCulture();
    };

    useEffect(() => {
        async function getCulture(){
            let results = await fetchToAPI(`https://app.toiyabe.us/api/admin/culture/${id}`)
            results = results.data;
            setCulture(results)
            setNewTribeName(results.name)
            setDescription(results.description)
            setPhoto(results.photo)
        }
        getCulture();
    }, [id])

    return (
        <div className="page">
            <div className="row">
                <div className="col-md-12" style={{display: 'flex'}}>
                    <div style={{flexDirection: 'column'}}>
                        <InputBase
                            onBlur={updateTribeName}
                            onSubmit={updateTribeName} 
                            value={newTribeName}
                            onChange={(e) => setNewTribeName(e.target.value)}
                            style={{fontSize: '28px', width:"100%", fontWeight: 'bold'}}
                        />
                        <div>
                            <h5>
                                (Click to edit title & description. Changes will be submitted dynamically.)
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{width: '100%', marginTop: 0}} />
            <div className="row" style={{marginBottom: '16px'}}>
                <div className="col-md-8">
                    <div className="row">
                        <h5 className="col-md-12" style={{fontSize: '14pt', fontWeight: 'bold'}}>Tribe Description</h5>
                    </div>
                    <div className="row">
                        <InputBase
                            className="col-md-10"
                            onBlur={updateTribeDescription}
                            onSubmit={updateTribeDescription} 
                            placeholder={description ? null : 'Type description here...'}
                            value={description ? description : ''}
                            multiline
                            rows={10}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{fontSize: '12pt', marginLeft: '16px'}}
                        /> 
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <h5 className="col-md-12" style={{fontSize: '14pt', fontWeight: 'bold'}}>Photo</h5>
                    </div>
                    <div className="row">
                    { photo === '' || photo === null ? (
                        <>
                            <IconButton style={{
                                    position: 'relative',
                                    display: 'flex',
                                    zIndex: 4,
                                    height: '11rem',
                                    width: '11rem',
                                    backgroundColor: '#3B7D7D',
                                    border: '2px solid #3B7D7D',
                                    borderRadius: 0,
                                }} 
                                onClick={() => document.getElementById('upload-image').click()}
                            >   
                                <MdFileUpload color="#FFF" />
                            </IconButton>
                            <input type='file' hidden name="image" id="upload-image" accept='image/*' onChange={(e) => {
                                    onImageChange(e)
                                }}
                            />
                        </>
                     )
                     : (
                        <div>
                            <img 
                                alt="culture"
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    zIndex: 4,
                                    height: '11.25rem',
                                    width: '11.25rem',

                                }} 
                                src={photo}
                            />
                            <div>
                                <button type="button" className="delete-word-button" style={{alignSelf: 'center'}} onClick={() => { handleDeletePhoto(); setPhoto(null);}}>
                                    Remove Photo
                                </button>
                            </div>
                        </div>
                     )}
                    </div>
                </div>
            </div>
            <div className="resiliancy-table" style={{float: 'left', width: "48%"}}>
                <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '12pt', float: 'left'}}>Word Phrase</p>
                    <div onClick={handleOpen} style={{fontWeight: 'bold', zIndex: 2, fontSize: '12pt', cursor: 'pointer'}}>Add Word Phrase +</div>
                </div>
                <hr style={{width: '100%',}} />
                    <table>
                        {culture ? culture.culture_words.map((obj) => {
                            return (
                                <tr key={'culture_words_'+obj.id} style={{display: 'flex', marginBottom: '8px', paddingLeft: '16px', paddingRight: '16px'}}>
                                    <td style={{alignSelf: 'center'}}>
                                        <div onClick={() => {
                                            getCultureWordToEdit(obj.id);
                                        }} style={{fontSize: '12pt', cursor: 'pointer'}}>
                                            {obj.phrase} - {obj.meaning}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        : null }
                    </table>
            </div>
            <div className="resiliancy-table" style={{float: 'right', width: "48%"}}>
                <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '12pt'}}>Courses (Up to 3)</p>
                    <div onClick={() => setOpenCourse(true)}style={{fontWeight: 'bold', fontSize: '12pt', zIndex: 2, cursor: 'pointer'}}>Add Courses +</div>
                </div>
                <hr style={{width: '100%'}} />
                <table>
                { culture ? culture.culture_courses.map((obj) => {
                    return (
                        <tr key={'culture_courses_'+obj.id} style={{display: 'flex', marginBottom: '8px', paddingLeft: '16px', paddingRight: '16px'}}>
                            <td style={{alignSelf: 'center'}}>
                                <div 
                                    onClick={() => {
                                        handleCultureToEdit(obj);
                                    }}
                                    style={{fontSize: '12pt', cursor: 'pointer'}}>
                                    {obj.title}
                                </div>
                            </td>
                        </tr>                            
                    )
                })
                : null }
                </table>
            </div>

            <div style={{left: '34.3rem', bottom: '37.82rem', position: 'relative'}} className="word-phrases-area">
               
            </div>
            <div style={{ width: '100%', position: 'relative', left:'38.5rem', bottom: '40.1rem' }}>
                
            </div>
            {/* Add Culture Word */}
            {open ? 
                <Formik
                initialValues={{
                    word: '',
                    definition: '',
                    audio: '',
                    photo: '',
                }}
                onSubmit={(values) => handleAddWord(values)}
                validationSchema={wordValidation}>
                {(formikProps) => (
                <Modal
                show={open}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addWordForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Word</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                            <div style={{paddingTop: '16px' }}>
                                <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                                >
                                    Word/Phrase
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('word')}
                                    onBlur={formikProps.handleBlur('word')}
                                    value={formikProps.values.word}
                                    size="small"
                                    style={{ 
                                        width: '100%', 
                                        position: 'relative',
                                    }}
                                    placeholder="Enter word/phrase"
                                />
                                <ErrorMessage name="word" component="div" className="text-danger" />
                            </div>
                            <div style={{paddingTop: '16px'}}>
                                <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                >
                                    Meaning
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('definition')}
                                    onBlur={formikProps.handleBlur('definition')}
                                    value={formikProps.values.definition}
                                    size="small"
                                    style={{ 
                                        width: '100%',
                                        position: 'relative',
                                    }}
                                    placeholder="Enter meaning"
                                />
                                <ErrorMessage name="definition" component="div" className="text-danger" />
                            </div>
                        </div>
                        <div style={{flexDirection: 'row', paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative'}}
                            >
                                Audio
                            </InputLabel>
                        </div>
                        <div style={{flexDirection: 'row', justifyContent: 'center', display: 'flex', padding: '16px'}}>
                            {audioToAdd ?
                                <div style={{flexDirection: 'column', justifyContent: 'center', display: 'flex',}}>
                                    <p style={{
                                        position: 'relative', 
                                        width: '90%',
                                        maxHeight: '3rem'
                                        }}>
                                        {audioToAddFileName}
                                    </p>
                                    <ReactAudioPlayer
                                    src={audioToAdd.url}
                                    controls
                                    />
                                    <button type="button" className="delete-word-button" style={{alignSelf: 'center'}} onClick={() => { setAudio(null); setAudioFileName(null); formikProps.setFieldValue('audio', '')}}>
                                    Remove
                                    </button>
                                </div>
                            :
                            <button type="button" onClick={() => document.getElementById('upload-audio-add').click()} className="upload-audio-button">
                                Upload Audio
                            </button>
                            }
                        </div>
                        <div style={{flexDirection: 'row', paddingBottom: '16px',}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', top: '0.3rem'}}
                            >
                                Add Photo/Illustration
                            </InputLabel>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {photoToAdd ? 
                                <div>
                                <img 
                                    alt="culture"
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        zIndex: 4,
                                        height: '9.75rem',
                                        width: '9.75rem',
                                    }} 
                                    src={photoToAdd.url}
                                />
                                <button type="button" onClick={() => { setPhotoToAdd(null); formikProps.setFieldValue('photo', '')}}>
                                Remove
                                </button>
                                </div>
                            :  
                                <IconButton style={{
                                    position: 'relative', 
                                    border: '52px solid #3B7D7D', 
                                    backgroundColor: '#3B7D7D', 
                                    borderRadius: 0
                                }} 
                                    onClick={() => document.getElementById('upload-photo-add').click()}
                                >
                                    <MdFileUpload color="#FFF" />
                                </IconButton>
                            }
                        </div>
                        <input type='file' hidden name="audio" id="upload-audio-add" accept='audio/*' onChange={(e) => {
                            const fr = new FileReader();
                            const file = e.target.files[0];
                            fr.onload = (ev) => {
                                setAudio({url: fr.result, file: file});
                            };
                            setAudioFileName(file.name);
                            fr.readAsDataURL(e.target.files[0]);
                            formikProps.setFieldValue('audio', file.name);
                            }}
                            
                        />
                        <input type='file' hidden name="photo" id="upload-photo-add" accept='image/*' onChange={(e) => {
                                const fr = new FileReader();
                                const file = e.target.files[0];
                                fr.onload = (ev) => {
                                    setPhotoToAdd({url: fr.result, file: file}); 
                                }
                                fr.readAsDataURL(file);
                            }}
                        />

                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="submit" style={{width: '340px', }} className="add-word-button">
                            Add Word
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }

            {openEdit ?
                <Formik
                initialValues={{
                    word: wordToEdit,
                    definition: meaningToEdit,
                    audio: '',
                    photo: '',
                }}
                onSubmit={(values) => handleEditWord(values)}
                validationSchema={wordValidation}>
                {(formikProps) => (
                <Modal
                show={openEdit}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                <form key="editWordForm">
                <Modal.Header closeButton>
                <Modal.Title>Edit Word</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Word/Phrase
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('word')}
                                onBlur={formikProps.handleBlur('word')}
                                value={formikProps.values.word}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter word/phrase"
                            />
                            <ErrorMessage name="word" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Meaning
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('definition')}
                                onBlur={formikProps.handleBlur('definition')}
                                value={formikProps.values.definition}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter meaning"
                            />
                            <ErrorMessage name="definition" component="div" className="text-danger" />
                        </div>
                    </div>
                    <div style={{flexDirection: 'row', paddingTop: '16px'}}>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative'}}
                        >
                            Audio
                        </InputLabel>
                    </div>
                    <div style={{flexDirection: 'row', justifyContent: 'center', display: 'flex', padding: '16px'}}>
                        {audioToEdit ?
                            <div style={{flexDirection: 'column', justifyContent: 'center', display: 'flex',}}>
                            <ReactAudioPlayer
                            src={audioToEdit.url}
                            controls
                            />
                            <button className="delete-word-button" type="button" onClick={() => { setAudioToEdit(null); formikProps.setFieldValue('audio', '')}}>
                            Remove Audio
                            </button>
                            </div>
                        :
                        <button type="button" onClick={() => document.getElementById('upload-audio-edit').click()} className="upload-audio-button">
                            Upload Audio
                        </button>
                        }
                    </div>
                    <div style={{flexDirection: 'row', paddingBottom: '16px',}}>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', top: '0.3rem'}}
                        >
                            Add Photo/Illustration
                        </InputLabel>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        {photoToEdit ? 
                            <div style={{flexDirection: 'column', justifyContent: 'center', display: 'flex',}}>
                            <img 
                                style={{
                                    position: 'relative',
                                    zIndex: 4,
                                    height: '9.75rem',
                                    width: '9.75rem',
                                    alignSelf: 'center',
                                }} 
                                src={photoToEdit.url}
                                alt="culture"
                            />
                            <button className="delete-word-button" type="button" onClick={() => { setPhotoToEdit(null); formikProps.setFieldValue('photo', '')}}>
                            Remove Image
                            </button>
                            </div>
                        :  
                            <IconButton style={{
                                position: 'relative', 
                                border: '52px solid #3B7D7D', 
                                backgroundColor: '#3B7D7D', 
                                borderRadius: 0
                            }} 
                                onClick={() => document.getElementById('upload-photo-edit').click()}
                            >
                                <MdFileUpload color="#FFF" />
                            </IconButton>
                        }
                    </div>
                    <input type='file' hidden name="audio" id="upload-audio-edit" accept='audio/*' onChange={(e) => {
                        const fr = new FileReader();
                        const file = e.target.files[0];
                        fr.onload = (ev) => {
                            setAudioToEdit({url: fr.result, file: file});
                        };
                        fr.readAsDataURL(e.target.files[0]);
                        formikProps.setFieldValue('audio', file.name);
                        }}
                        
                    />
                    <input type='file' hidden name="photo" id="upload-photo-edit" accept='image/*' onChange={(e) => {
                            const fr = new FileReader();
                            const file = e.target.files[0];
                            fr.onload = (ev) => {
                                setPhotoToEdit({url: fr.result, file: file}); 
                            }
                            fr.readAsDataURL(file);
                        }}
                    />
                </Modal.Body>
                <Modal.Footer style={{justifyContent: 'center'}}>
                    <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.submitForm}>
                    Save Word
                    </button>
                    <button type="button" style={{width: '340px', }} className="delete-word-button" onClick={deleteWord}>
                    Delete Word
                    </button>
                </Modal.Footer>
            </form>
            </Modal>
            )}
            </Formik>
            :
                null
            }
            {/* Add Course Modal */}
            {openCourse ?
                <Formik
                initialValues={{
                    title: '',
                    description: '',
                    location: '',
                    eventDate: '',
                    eventStart: '',
                    eventEnd: '',
                }}
                onSubmit={(values) => handleAddCourse(values)}
                validationSchema={courseValidation}>
                {(formikProps) => (
                <form key="addCourseForm">
                <Modal
                    show={openCourse}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                <Modal.Header closeButton>
                <Modal.Title>Add Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{flexDirection: 'column'}}>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative'}}>
                                Title
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('title')}
                            onBlur={formikProps.handleBlur('title')}
                            value={formikProps.values.title}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            placeholder="Enter title of course"
                        />
                        <ErrorMessage name="title" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Description
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('description')}
                            onBlur={formikProps.handleBlur('description')}
                            value={formikProps.values.description}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            placeholder="Enter description of course"
                        />
                        <ErrorMessage name="description" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Photo
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {newCulturePhoto ? 
                                        <div style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                            <img src={newCulturePhoto.url} alt="culture" style={{position: 'relative', height: '188px', width: '200px' }} />
                                            <button type="button" className="delete-word-button" onClick={() => { setNewCulturePhoto(null);}}>
                                                Remove Image
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('course-upload-image-add').click()}  style={{position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} onClick={() => document.getElementById('course-upload-image-add').click()} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept="image/*" hidden name="image" id="course-upload-image-add" onChange={(e) => {
                                        const fr = new FileReader();
                                        const file = e.target.files[0];
                                        fr.onload = (ev) => {
                                            setNewCulturePhoto({url: fr.result, file: file}); 
                                        };
                                        fr.readAsDataURL(file);
                                        }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Location
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('location')}
                            onBlur={formikProps.handleBlur('location')}
                            value={formikProps.values.location}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            placeholder="Enter location of course"
                        />
                        <ErrorMessage name="location" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Event Date
                        </InputLabel>
                        <TextField
                            onChange={formikProps.handleChange('eventDate')}
                            onBlur={formikProps.handleBlur('eventDate')}
                            value={formikProps.values.eventDate}
                            style={{
                                zIndex: 2, 
                                position: 'relative',
                                width: '100%',
                            }}
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <ErrorMessage name="eventDate" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Start Time
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('eventStart')}
                            onBlur={formikProps.handleBlur('eventStart')}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            type="time"
                            placeholder="hh:mm:ss (military time)"
                        />
                        <ErrorMessage name="eventStart" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            End Time
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('eventEnd')}
                            onBlur={formikProps.handleBlur('eventEnd')}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            type="time"
                            placeholder="hh:mm:ss (military time)"
                        />
                        <ErrorMessage name="eventEnd" component="div" className="text-danger" />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: 'center'}}>
                    <button type="button" style={{width: '340px',}} className="add-word-button" onClick={formikProps.handleSubmit}>
                        Add Event
                    </button>
                </Modal.Footer>
            </Modal>
            </form>
            )}
            </Formik>
            :
                null
            }

            {editCourse ?
                <Formik
                initialValues={{
                    title: editCultureTitle,
                    description: editCultureDescription,
                    location: editCultureLocation,
                    eventDate: editCultureDate,
                    eventStart: editCultureStartTime,
                    eventEnd: editCultureEndTime,
                }}
                onSubmit={(values) => handleEditCulture(values)}
                validationSchema={courseValidation}>
                {(formikProps) => (
                <form key="addCourseForm">
                <Modal
                    show={editCourse}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{flexDirection: 'column'}}>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative'}}>
                                Title
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('title')}
                            onBlur={formikProps.handleBlur('title')}
                            value={formikProps.values.title}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            placeholder="Enter title of course"
                        />
                        <ErrorMessage name="title" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Description
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('description')}
                            onBlur={formikProps.handleBlur('description')}
                            value={formikProps.values.description}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            placeholder="Enter description of course"
                        />
                        <ErrorMessage name="description" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Photo
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {editCulturePhoto ? 
                                        <div style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                            <img src={editCulturePhoto.url} alt="culture" style={{position: 'relative', height: '188px', width: '200px' }} />
                                            <button type="button" className="delete-word-button" onClick={() => { setEditCulturePhoto(null);}}>
                                                Remove Image
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('course-upload-image-edit').click()}  style={{position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} onClick={() => document.getElementById('course-upload-image-edit').click()} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept="image/*" hidden name="image" id="course-upload-image-edit" onChange={(e) => {
                                        const fr = new FileReader();
                                        const file = e.target.files[0];
                                        fr.onload = (ev) => {
                                            setEditCulturePhoto({url: fr.result, file: file}); 
                                        };
                                        fr.readAsDataURL(file);
                                        }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Location
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('location')}
                            onBlur={formikProps.handleBlur('location')}
                            value={formikProps.values.location}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            placeholder="Enter location of course"
                        />
                        <ErrorMessage name="location" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Event Date
                        </InputLabel>
                        <TextField
                            onChange={formikProps.handleChange('eventDate')}
                            onBlur={formikProps.handleBlur('eventDate')}
                            value={formikProps.values.eventDate}
                            style={{
                                zIndex: 2, 
                                position: 'relative',
                                width: '100%',
                            }}
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <ErrorMessage name="eventDate" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Start Time
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('eventStart')}
                            onBlur={formikProps.handleBlur('eventStart')}
                            defaultValue={formikProps.values.eventStart}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            type="time"
                            placeholder="hh:mm:ss (military time)"
                        />
                        <ErrorMessage name="eventStart" component="div" className="text-danger" />
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            End Time
                        </InputLabel>
                        <TextField 
                            onChange={formikProps.handleChange('eventEnd')}
                            onBlur={formikProps.handleBlur('eventEnd')}
                            defaultValue={formikProps.values.eventEnd}
                            style={{ 
                                position: 'relative',
                                width: '100%',
                            }}
                            type="time"
                            placeholder="hh:mm:ss (military time)"
                        />
                        <ErrorMessage name="eventEnd" component="div" className="text-danger" />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: 'center'}}>
                    <button type="button" style={{width: '340px',}} className="add-word-button" onClick={formikProps.handleSubmit}>
                        Update Course
                    </button>
                    <button type="button" style={{width: '340px',}} className="delete-word-button" onClick={deleteCourse}>
                        Delete Course
                    </button>
                </Modal.Footer>
            </Modal>
            </form>
            )}
            </Formik>
                
            :
                null
            }
        </div>
    )
}
