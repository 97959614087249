import React, { useState, useEffect } from 'react'
import { TextField, InputLabel } from '@material-ui/core'
import { fetchToAPI, deleteItem, postApplicationJson} from '../commonItems/Fetch';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';

const DEFAULT_PROVIDER = {
    name: '',
    info: '',
    id: null,
};

export default function ProviderHome() {
    const [open, setOpen] = React.useState(false);
    const [provider, setProvider] = useState(DEFAULT_PROVIDER);
    const [providers, setProviders] = useState([]);

    const providerValidation = yup.object().shape({
        name: yup.string()
            .label('Provider Name')
            .required(),
        info: yup.string()
            .label('Provider Info')
            .required(),
    });


    const editProvider = (obj) => {
        setProvider(obj)
        setOpen(true);
    }

    const getProviders = async () => {
        const providers = await fetchToAPI('https://app.toiyabe.us/api/providers');
        setProviders(providers.data);
    };

    useEffect(() => {
        getProviders();
    }, [])

    const handleSubmitProvider = async (values) => {
        console.log(`got values: ${values}`)
        let endpoint;
        const body = new FormData();
        body.append("name", values.name);
        body.append("info", values.info);

        if (provider.id) {
            body.append("_method", "put");
            endpoint = `https://app.toiyabe.us/api/admin/provider/${provider.id}`;
            setProvider(DEFAULT_PROVIDER);
        } else {
            endpoint = 'https://app.toiyabe.us/api/admin/provider';
        }
        await postApplicationJson(endpoint, body);
        onHideHandler();
        getProviders();
    };

    const deleteProvider = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/provider/${provider.id}`);
        onHideHandler();
        getProviders();
    };

    const onHideHandler = () => {
        setOpen(false);
        setProvider(DEFAULT_PROVIDER);
    };

    return (
        <div className="page">
                <div className="resiliancyHeader">
                    <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>Providers</h3>
                </div>
                <div className="resiliancyHeader">
                    <p style={{fontWeight: '500',fontSize: '14pt', }}>Providers</p>
                    <p onClick={() => setOpen(true)} style={{ fontWeight: '600', cursor: 'pointer', }}>
                        Add Provider +
                    </p>
                </div>
                <div className="resiliancy-table">
                    <table className="table table-borderless">
                    {providers.map((obj) => {
                        return (
                            <tr>
                                <td>
                                    <div style={{fontSize: '16pt', cursor: 'pointer' }} onClick={() => editProvider(obj)}>
                                        <p >{obj.name}</p>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </table>
                </div>

            {/* ADD/EDIT PROVIDER MODAL */}
            {open ?
                <Formik
                initialValues={(provider)}
                onSubmit={(values) => handleSubmitProvider(values)}
                validationSchema={providerValidation}>
                {(formikProps) => (
                <Modal
                show={open}
                onHide={() => onHideHandler()}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addWordForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>{provider.id ? 'Edit' : 'Add'} Provider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Provider Name
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Provider name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Provider Info
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('info')}
                                onBlur={formikProps.handleBlur('info')}
                                value={formikProps.values.info}
                                size="small"
                                multiline
                                rows={4}
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter provider info"
                            />
                            <ErrorMessage name="info" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            { provider.id ? 'Save' : 'Add' } Provider
                        </button>
                        {
                            provider.id ?
                                <button style={{flexDirection: 'row', justifySelf: 'center'}} type="button" className="delete-word-button" onClick={deleteProvider}>
                                    Delete Provider
                                </button>
                            :
                            null
                        }
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
        </div>
    )
}
