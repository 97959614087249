import React, { useState, useEffect } from 'react'
import CreateIcon from '@material-ui/icons/CreateOutlined';
import { TextField, InputLabel } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { fetchToAPI, postApplicationJson, updatePhoto, deleteItem } from '../commonItems/Fetch';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';

const treatmentValidation = yup.object().shape({
    name: yup.string()
        .label('Treatment')
        .required(),
    description: yup.string()
        .label('Description')
        .required(),
});

const sectionHeaderValidation = yup.object().shape({
    description: yup.string()
        .label('Description')
        .required(),
}); 

export default function TreatmentsHome() {
    const [treatments, setTreatments] = useState(null);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [treatmentToEdit, setTreatmentToEdit] = useState();
    const [titleToEdit, setTitleToEdit] = useState();
    const [descriptionToEdit, setDescriptionToEdit] = useState();
    const [sectionHeader, setSectionHeader] = useState();

    const getTreatments = async () => {
        const result = await fetchToAPI('https://app.toiyabe.us/api/admin/treatment');
        console.log("getTreatments", result);
        setTreatments(result.data);
    }

    const getSectionHeader = async () => {
        const result = await fetchToAPI('https://app.toiyabe.us/api/appinfo/treatment');
        setSectionHeader(result.data ?? '');
    }

    useEffect(() => {
        getTreatments();
        getSectionHeader();
    }, []);

    const handleCreateTreatment = async (values) => {
        await postApplicationJson('https://app.toiyabe.us/api/admin/treatment/', {
            name: values.name,
            description: values.description,
        });

        getTreatments()
        setOpen(false);
    };

    const prepareEditTreatment = async (id) => {
        const result = await fetchToAPI(`https://app.toiyabe.us/api/admin/treatment/${id}`);
        setTreatmentToEdit(result.data);
        setTitleToEdit(result.data.name);
        setDescriptionToEdit(result.data.description);
        setOpenEdit(true);
    };
    
    const handleEditTreatment = async (values) => {
        let formData = new FormData();
        formData.append('_method', 'put');
        formData.append('name', values.name);
        formData.append('description', values.description);

        await updatePhoto(`https://app.toiyabe.us/api/admin/treatment/${treatmentToEdit.id}`, formData);
        getTreatments();
        setOpenEdit(false);
    };

    const handleDeleteTreatment = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/treatment/${treatmentToEdit.id}`);
        setTreatmentToEdit(null);
        getTreatments();
        setOpenEdit(false);
    };

    const handleUpdateHeader = async (values) => {
        const body = new FormData();
        body.append("key", "treatment");
        body.append("value", values.description);
        body.append("_method", "put");
        let result = await updatePhoto('https://app.toiyabe.us/api/admin/appinfo', body);
        getSectionHeader();
        alert(result.data.message);
    }


    return (
        <div className="page">
            <div className="resiliancyHeader">
                <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>Treatments</h3>
                <button onClick={() => setOpen(true)} 
                style={{    
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                    backgroundColor: '#3B7D7D',
                }}>Add +</button>
            </div>
            <div style={{borderBottom:'solid 2px #bababa', paddingBottom: '10px', marginBottom: '20px'}}>
                <Formik
                    initialValues={{
                        description: sectionHeader,
                    }}
                    enableReinitialize
                    onSubmit={(values) => handleUpdateHeader(values)}
                    validationSchema={sectionHeaderValidation}>
                    {(formikProps) => (
                    <div style={{display: 'flex', paddingTop: '16px', flexDirection: 'row'}}>
                        <div style={{width: '66%', }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Treatment Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                multiline
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter treatment section description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                        <div style={{marginLeft: 12, width: '33%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <button style={{minWidth: '100px'}} type="button" className="delete-tribe-button" onClick={formikProps.resetForm}>
                                Reset Text
                            </button>
                            <button style={{minWidth: '100px', marginTop: '16px'}} type="button" className="add-tribe-button" onClick={formikProps.handleSubmit}>
                                Save Text
                            </button>
                        </div>
                    </div>
                )}
                </Formik>
            </div>
            <hr style={{position: 'relative', borderWidth: 2}} />
            <div className="col-md-12" style={{position: 'relative', zIndex: 2}}>
                {treatments && Object.keys(treatments).map((key) => {
                    const item = treatments[key];
                    const id = item.id
                    return (
                        <div
                            className="row"
                            key={'treatment_'+id}
                            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '16px', paddingRight: '16px', marginBottom: '8px', height: '4rem', backgroundColor: '#FFF'}}     
                        >
                            <Link style={{ textDecoration: 'none', color: '#000'}} to={`/treatments/${item.name},${id}`}>
                                <div>{item.name}</div>
                            </Link>
                            <CreateIcon 
                                color="#000"
                                style={{ 
                                    fontSize: 30,
                                    cursor: 'pointer',
                                }}
                                onClick={() => prepareEditTreatment(id)}
                            />
                        </div>
                    )
                })}
            </div>

        {/* ADD TREATMENT MODAL */}
        <Formik
            initialValues={{
                name: '',
                description: '',
            }}
            onSubmit={(values) => handleCreateTreatment(values)}
            validationSchema={treatmentValidation}>
            {(formikProps) => (
                <Modal
                show={open}
                onHide={() => { formikProps.resetForm(); setOpen(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Treatment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Treatment Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter treatment title"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                multiline
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter a description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="submit" style={{width: '340px', }} className="add-tribe-button">
                            Save Treatment
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
        </Formik>

        {/* EDIT TREATMENT MODAL */}
        <Formik
            initialValues={{
                name: titleToEdit,
                description: descriptionToEdit,
            }}
            enableReinitialize
            onSubmit={(values) => handleEditTreatment(values)}
            validationSchema={treatmentValidation}>
            {(formikProps) => (
                <Modal
                show={openEdit}
                onHide={() => { formikProps.resetForm(); setOpenEdit(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Save Treatment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Treatment Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter treatment title"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                multiline
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter a description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="submit" style={{width: '340px', }} className="add-tribe-button">
                            Save Treatment
                        </button>
                        <button onClick={handleDeleteTreatment} style={{width: '340px'}} className="delete-tribe-button">
                            Delete Treatment
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
        </Formik>
        </div>
    )
}
