import React, { useState, useEffect, createRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputLabel, IconButton, Select, MenuItem } from '@material-ui/core'
import { FaPlus, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdFileUpload } from "react-icons/md";
import ClearIcon from '@material-ui/icons/Clear';
import { FaTimes } from 'react-icons/fa';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import { fetchToAPI, updatePhoto, deleteItem, postApplicationJson, putApplicationJson } from '../commonItems/Fetch';
import Dropdown from 'react-dropdown';
import qs from 'qs';
import './Information.css'
import {Formik, FieldArray, ErrorMessage} from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Dropzone from 'react-dropzone';

export default function InformationHome() {
    const [clinics, setClinics] = useState([]);
    const [services, setServices] = useState([]);
    const [groupSessions, setGroupSessions] = useState([]);
    const [departments, setDepartments] = useState([{
        name: '',
        phoneNumberOne: '',
        phoneNumberTwo: '',
    }]);
    const [photos, setPhotos] = useState([
        {  
            id: 1,
            url: null,
            file: null
        }
    ]);
    const [ready, setReady] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openService, setOpenService] = useState(false);
    const [openEditService, setOpenEditService] = useState(false);
    const [openGroupSession, setOpenGroupSession] = useState(false);
    const [openEditGroupSession, setOpenEditGroupSession] = useState(false);
    const [dropDownValue, setDropDownValue] = useState('');
    const [clinicToEdit, setClinicToEdit] = useState();
    const [clinicIdToEdit, setClinicIdToEdit] = useState();
    const [serviceToEdit, setServiceToEdit] = useState();
    const [groupToEdit, setGroupToEdit] = useState();
    const [photoToDelete, setPhotoToDelete] = useState('');
    const [options, setOptions] = useState([]);
    const [newClinicPhotos, setnewClinicPhotos] = useState(null);
    const [clinicNameToEdit, setClinicNameToEdit] = useState(null);
    const [clinicAddressToEdit, setClinicAddressToEdit] = useState(null);
    const [clinicEmailToEdit, setClinicEmailToEdit] = useState(null);
    const [clinicDepartmentsToEdit, setClinicDepartmentsToEdit] = useState(null);
    const [newServicePhotos, setNewServicePhotos] = useState([]);
    const [serviceNameToEdit, setServiceNameToEdit] = useState(null);
    const [serviceDescriptionToEdit, setServiceDescriptionToEdit] = useState(null);
    const [serviceClinicIdToEdit, setServiceClinicIdToEdit] = useState(null);
    const [serviceAwardsToEdit, setServiceAwardsToEdit] = useState([]);
    const [serviceOfferingsToEdit, setServiceOfferingsToEdit] = useState([]);
    const [servicePhotosToEdit, setServicePhotosToEdit] = useState([]);
    const [servicePhotosToDelete, setServicePhotosToDelete] = useState([]);
    const [newGroupName, setNewGroupName] = useState(null);
    const [newGroupDescription, setNewGroupDescription] = useState(null);
    const [newGroupDate, setNewGroupDate] = useState(null);
    const [newGroupStartTime, setNewGroupStartTime] = useState(null);
    const [newGroupEndTime, setNewGroupEndTime] = useState(null);
    const [groupSessionIdToEdit, setGroupSessionIdToEdit] = useState(null);
    const [groupNameToEdit, setGroupNameToEdit] = useState(null);
    const [groupClinicToEdit, setGroupClinicToEdit] = useState(null);
    const [groupDescriptionToEdit, setGroupDescriptionToEdit] = useState(null);
    const [groupDateToEdit, setGroupDateToEdit] = useState(null);
    const [groupStartTimeToEdit, setGroupStartTimeToEdit] = useState(null);
    const [groupEndTimeToEdit, setGroupEndTimeToEdit] = useState(null);
    const [facebookUrlToEdit, setFacebookUrlToEdit] = useState(null);
    const [linkedInUrlToEdit, setLinkedInUrlToEdit] = useState(null);
    const [narcanUrlToEdit, setNarcanUrlToEdit] = useState(null);
    const [narcanPdf, setNarcanPdf] = useState(null);
    const dropzoneRef = createRef();

    const socialValidation = yup.object().shape({
        facebook: yup.string()
            .required()
            .label('Facebook URL'),
        linkedIn: yup.string()
            .required()
            .label('Linked In'),
    });

    const departmentValidation = yup.object().shape({
        name: yup.string()
            .required()
            .label('Department Name'),
        phone1: yup.string()
            .required()
            .label('Phone Number 1'),
        phone1Extension: yup.string()
            .label('Extension 1'),
        phone2: yup.string()
            .label('Phone Number 2 '),
        phone2Extension: yup.string()
            .label('Extension 2'),
    });

    const clinicValidation = yup.object().shape({
        name: yup.string()
            .label('Clinic Name')
            .required(),
        address: yup.string()
            .label('Clinic Address')
            .required(),
        email: yup.string()
            .email()
            .label('Clinic Email')
            .required(),
        departments: yup.array().of(departmentValidation)
    });

    const groupSessionValidation = yup.object().shape({
        name: yup.string()
            .label('Group Session Name')
            .required(),
        clinic: yup.string()
            .label('Clinic')
            .required(),
        date: yup.string()
            .label('Date')
            .required(),
        startTime: yup.string()
            .label('Start Time')
            .required(),
        endTime: yup.string()
            .label('End Time')
            .required(),
        description: yup.string()
            .label('Description')
            .required(),
    });

    const serviceValidation = yup.object().shape({
        name: yup.string()
            .label('Service Name')
            .required(),
        clinic: yup.string()
            .label('Clinic')
            .required(),
        who: yup.string()
            .label('Who we are')
            .required(),
        awards: yup.string()
            .label('Our Awards and Acheivements'),
        offer: yup.string()
            .label('What we offer')
            .required(),
    });

    const editClinic = (obj) => {
        setClinicIdToEdit(obj.id);
        setClinicToEdit(obj);
        setClinicNameToEdit(obj.name);
        setClinicAddressToEdit(obj.address);
        setClinicEmailToEdit(obj.email);
        
        let depts = obj.clinic_departments.map( obj => {
            let phone1 = obj.clinic_department_contacts[0]?.phone ?? '';
            let phone1Ext = obj.clinic_department_contacts[0]?.extension ?? '';
            let phone2 = obj.clinic_department_contacts[1]?.phone ?? '';
            let phone2Ext = obj.clinic_department_contacts[1]?.extension ?? '';
            return {
                name: obj.department,
                phone1: phone1,
                phone1Extension: phone1Ext,
                phone2: phone2,
                phone2Extension: phone2Ext,
            }
        });

        setClinicDepartmentsToEdit(depts);

        setOpenEdit(true);
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
        let reader = new FileReader(); 
        const file = event.target.files[0];
        reader.addEventListener("load", function () {
            const newPhotos = [{
                id: photos.length,
                url: reader.result,
                file: file
            }, ...photos].flat()
            setPhotos(newPhotos);
        }, false);
        reader.readAsDataURL(event.target.files[0]);
        }   
    };

    const fetchSocial = async () => {
        let facebook = await fetchToAPI(`https://app.toiyabe.us/api/appinfo/facebook`);
        setFacebookUrlToEdit(facebook.data);
        let linkedIn = await fetchToAPI(`https://app.toiyabe.us/api/appinfo/linkedin`);
        setLinkedInUrlToEdit(linkedIn.data);
    }

    const fetchNarcan = async () => {
        let narcan = await fetchToAPI('https://app.toiyabe.us/api/appinfo/narcan/pdf');
        setNarcanUrlToEdit(narcan.data);
        setNarcanPdf(narcan.data ? {url: narcan.data, item: null} : null);
    }

    const handleSaveNarcan = async (values) => {
        const formBody = new FormData();
        formBody.append("key", "narcan");
        formBody.append("value", "narcan");
        formBody.append('pdf', narcanPdf.file);
        formBody.append("_method", "put");
        await updatePhoto('https://app.toiyabe.us/api/admin/appinfo', formBody);

        fetchNarcan();
    }

    const handleSaveSocial = async (values) => {
        const fbBody = new FormData();
        fbBody.append("key", "facebook");
        fbBody.append("value", values.facebook);
        fbBody.append("_method", "put");
        await updatePhoto('https://app.toiyabe.us/api/admin/appinfo', fbBody);

        const linkedBody = new FormData();
        linkedBody.append("key", "linkedin");
        linkedBody.append("value", values.linkedIn);
        linkedBody.append("_method", "put");
        await updatePhoto('https://app.toiyabe.us/api/admin/appinfo', linkedBody);

        fetchSocial();
    }

    const getSingleServiceInfo = async (id) => {
        const service = await fetchToAPI(`https://app.toiyabe.us/api/services/${id}`);
        const item = service.data;
        setServiceToEdit(item);
        setServiceNameToEdit(item.name);
        setServiceClinicIdToEdit(item.clinic_id);
        setServiceAwardsToEdit(item.awards);
        setServiceOfferingsToEdit(item.offerings);
        setServiceDescriptionToEdit(item.description);
        setServicePhotosToEdit(item.service_photos);
        setOpenEditService(true);
    };

    const getClinics = async () => {
        const clinics = await fetchToAPI('https://app.toiyabe.us/api/clinics');
        setClinics(clinics.data);
        const data = [];
        clinics.data.map(obj => {
            data.push({
                id: obj.id,
                text: obj.name,
                value: obj.name
            })
        });
        setOptions(data);
    };

    const getServices = async () => {
        const services = await fetchToAPI('https://app.toiyabe.us/api/services/');
        let mapped = Object.keys(services.data).map( value => {
            return {id: value, name: services.data[value]};
        })
        setServices(mapped);
    };

    const getSessions = async () => {
        const sessions = await fetchToAPI('https://app.toiyabe.us/api/groupsessions');
        setGroupSessions(sessions.data.data);
    };

    useEffect(() => {
        getClinics();
        getServices();
        getSessions();
        fetchSocial();
        fetchNarcan();
        setReady(true);
    }, [])

    const handleAddClinic = async (values) => {
        const body = new FormData();
        values.departments.forEach((obj, index) => {
            body.append(`departments[${index}][department]`, obj.name);
            body.append(`departments[${index}][clinic_department_contacts][0][phone]`, obj.phone1);
            body.append(`departments[${index}][clinic_department_contacts][0][extension]`, obj.phone1Extension ?? 0);
            if (obj.phone2) {
                body.append(`departments[${index}][clinic_department_contacts][1][phone]`, obj.phone2);
                body.append(`departments[${index}][clinic_department_contacts][1][extension]`, obj.phone2Extension ?? 0);
            }
        });

        photos.forEach(obj => {
            if(obj.file) {
                body.append('photos[]', obj.file);
            }
        })

        body.append("name", values.name);
        body.append("address", values.address);
        body.append("email", values.email);
        await updatePhoto('https://app.toiyabe.us/api/admin/clinic', body);
        getClinics();
        setOpen(false);
    };
    
    const handleEditClinic = async (values) => {
        //post form data
        const body = new FormData();

        body.append("name", values.name);
        body.append("address", values.address);
        body.append("email", values.email);

        photos.forEach(obj => {
            if(obj.file) {
                body.append('photos[]', obj.file);
            }
        })

        values.departments.forEach((obj, index) => {
            body.append(`departments[${index}][department]`, obj.name);
            body.append(`departments[${index}][clinic_department_contacts][0][phone]`, obj.phone1);
            body.append(`departments[${index}][clinic_department_contacts][0][extension]`, obj.phone1Extension ?? 0);
            if (obj.phone2) {
                body.append(`departments[${index}][clinic_department_contacts][1][phone]`, obj.phone2);
                body.append(`departments[${index}][clinic_department_contacts][1][extension]`, obj.phone2Extension ?? 0);
            }
        });

        body.append("_method", "put");
        await updatePhoto(`https://app.toiyabe.us/api/admin/clinic/${clinicIdToEdit}`, body);
        getClinics();
        setOpenEdit(false);
    };

    const deleteClinic = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/clinic/${clinicIdToEdit}`);
        getClinics();
        setOpenEdit(false);
    };

    const handleEditGroupSession = async (obj) => {
        const clinic = await fetchToAPI(`https://app.toiyabe.us/api/clinics/${obj.clinic_id}`)
        const GSWLocation = {
            ...obj,
            location: clinic.data.address
        }
        setGroupSessionIdToEdit(obj.id);
        setGroupToEdit(GSWLocation);
        setGroupNameToEdit(GSWLocation.name);
        setGroupClinicToEdit(obj.clinic_id);
        setGroupDescriptionToEdit(GSWLocation.description);
        setGroupDateToEdit(GSWLocation.session_date);
        setGroupStartTimeToEdit(moment(GSWLocation.session_start_time, 'HH:mm:ss').format('HH:mm'));
        setGroupEndTimeToEdit(moment(GSWLocation.session_end_time, 'HH:mm:ss').format('HH:mm'));
        setOpenEditGroupSession(true)
    };

    const handleAddService = async (values) => {
        const body = new FormData();

        newServicePhotos.forEach( (obj, index) => {
            if(obj.file) {
                body.append(`photos[${index}]`, obj.file);
            }
        })

        body.append("name", values.name);
        body.append("clinic_id", values.clinic);

        if(values.who) {
            body.append("awards", values.awards);
        }
        if(values.who) {
            body.append("description", values.who);
        }
        if(values.offer) {
            body.append("offerings", values.offer);
        }
        await updatePhoto('https://app.toiyabe.us/api/admin/service', body);
        getServices();
        handleCloseAddService();
    };

    const handleNewRemovePhoto = (id) => {
        setNewServicePhotos(prev => {
            let index = prev.findIndex( item => item.id === id);
            if(index >= 0) {
                return prev.splice(index, 1);
            } else {
                return prev;
            }
        })
    }

    const handleCloseAddService = () => {
        setOpenService(false);
        setPhotos(null);
    }

    const handleEditRemovePhoto = (id) => {
        if (id > 0) {
            let intArr = [id];
            setServicePhotosToDelete(prev => [...prev, ...intArr]);
        }
        setServicePhotosToEdit(prev => {
            let index = prev.findIndex( item => item.id === id);
            if(index >= 0) {
                return prev.splice(index, 1);
            } else {
                return prev;
            }
        })
    }

    const handleEditService = async (values) => {
        const body = new FormData();
        body.append("_method", "put");
        if(servicePhotosToDelete.length > 0) {
            servicePhotosToDelete.map((photoToDelete, index) => {
                body.append(`deletephotos[${index}]`, photoToDelete);
            });
        } 
        if(servicePhotosToEdit.length > 0) {
            servicePhotosToEdit.map( (photo, index) => {
                if(photo.id < 0) {
                    body.append(`photos[${index}]`, photo.file);
                }
            })
        }

        body.append("name", values.name);
        body.append("clinic_id", values.clinic);

        if(values.who) {
            body.append("awards", values.awards);
        }
        if(values.who) {
            body.append("description", values.who);
        }
        if(values.offer) {
            body.append("offerings", values.offer);
        }
        await updatePhoto(`https://app.toiyabe.us/api/admin/service/${serviceToEdit.id}`, body);
        getServices();
        handleCloseEditService();
    };

    const handleCloseEditService = () => {
        setOpenEditService(false);
        setServicePhotosToEdit([]);
        setServicePhotosToDelete([]);
    }

    const handleDeleteService = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/service/${serviceToEdit.id}`);
        getServices();
        setOpenEditService(false);
    }

    const handleAddGroupSession = async (values) => {
        await postApplicationJson('https://app.toiyabe.us/api/admin/clinicsession', {
            clinic_id: values.clinic,
            name: values.name,
            description: values.description,
            session_date: values.date, 
            session_start_time: moment(values.startTime, 'HH:mm').format('HH:mm:ss'),
            session_end_time: moment(values.endTime, 'HH:mm').format('HH:mm:ss'), 
        });
        getSessions();
        setOpenGroupSession(false);
    };

    const handleSaveGroupSession = async (values) => {
        const al = await putApplicationJson(`https://app.toiyabe.us/api/admin/clinicsession/${groupSessionIdToEdit}`, {
            clinic_id: values.clinic,
            name: values.name,
            description: values.description,
            session_date: values.date, 
            session_start_time: moment(values.startTime, 'HH:mm').format('HH:mm:ss'),
            session_end_time: moment(values.endTime, 'HH:mm').format('HH:mm:ss'), 
        });
        alert(al.data.message);
        getSessions();
        setOpenEditGroupSession(false);
    };

    const deleteGroupSession = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/clinicsession/${groupSessionIdToEdit}`);
        getSessions();
        setOpenEditGroupSession(false);
    };


    return (
        <div className="page">
                <div className="resiliancyHeader">
                    <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>Information</h3>
                </div>
                <div className="resiliancyHeader">
                    <p style={{fontWeight: '500',fontSize: '14pt', }}>Toiyabe Social</p>
                </div>
                <div className="resiliancy-table">
                    <Formik
                    initialValues={{
                        facebook: facebookUrlToEdit,
                        linkedIn: linkedInUrlToEdit,
                    }}
                    enableReinitialize
                    onSubmit={(values) => handleSaveSocial(values)}
                    validationSchema={socialValidation}>
                    {(formikProps) => (
                        <div>
                            <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                                <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                                >
                                    Facebook URL
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('facebook')}
                                    onBlur={formikProps.handleBlur('facebook')}
                                    value={formikProps.values.facebook}
                                    size="small"
                                    style={{ 
                                        width: '100%', 
                                        position: 'relative',
                                    }}
                                    placeholder="Enter Facebook URL"
                                />
                                <ErrorMessage name="facebook" component="div" className="text-danger" />
                            </div>
                            <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                                <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                                >
                                    LinkedIn URL
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('linkedIn')}
                                    onBlur={formikProps.handleBlur('linkedIn')}
                                    value={formikProps.values.linkedIn}
                                    size="small"
                                    style={{ 
                                        width: '100%', 
                                        position: 'relative',
                                    }}
                                    placeholder="Enter LinkedIn URL"
                                />
                                <ErrorMessage name="linkedIn" component="div" className="text-danger" />
                            </div>
                            <button type="button" style={{width: '340px', marginBottom: '32px'}} className="add-word-button" onClick={formikProps.handleSubmit}>
                                Save Social
                            </button>
                        </div>
                    )}
                    </Formik>
                </div>
                <div className="resiliancyHeader">
                    <p style={{fontWeight: '500',fontSize: '14pt', }}>Narcan</p>
                </div>
                <div className="resiliancy-table">
                    <Formik
                    initialValues={{
                        narcan: narcanUrlToEdit,
                    }}
                    enableReinitialize
                    onSubmit={(values) => handleSaveNarcan(values)}>
                    {(formikProps) => (
                        <div>
                            <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                                <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                                >
                                    Narcan PDF
                                </InputLabel>
                                <Dropzone ref={dropzoneRef} noClick noKeyboard>
                                    {({getRootProps, getInputProps, acceptedFiles}) => {
                                        return (
                                        <div className="container">
                                            <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            {narcanPdf ? 
                                                <div style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                                    <p style={{position: 'relative', fontSize: '18pt'}}>Narcan Information</p>
                                                    <button type="button" className="delete-word-button" onClick={() => { setNarcanPdf(null);}}>
                                                        Remove PDF
                                                    </button>
                                                </div>
                                            : 
                                                <IconButton onClick={() => document.getElementById('course-upload-pdf-add').click()} style={{position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                                    <MdFileUpload size={40} color="#FFF" />
                                                </IconButton>
                                            }
                                            </div>
                                            <input type='file' accept="application/pdf" hidden name="pdf" id="course-upload-pdf-add" onChange={(e) => {
                                                const fr = new FileReader();
                                                let file = e.target.files[0];
                                                setNarcanPdf({url: file.name, file: file}); 
                                                fr.readAsDataURL(file);
                                                }}
                                            />
                                        </div>
                                        );
                                    }}
                                </Dropzone>
                                <ErrorMessage name="narcan" component="div" className="text-danger" />
                            </div>
                            <button type="button" style={{width: '340px', marginBottom: '32px'}} className="add-word-button" onClick={formikProps.handleSubmit}>
                                Save Narcan
                            </button>
                        </div>
                    )}
                    </Formik>
                </div>
                <div className="resiliancyHeader">
                    <p style={{fontWeight: '500',fontSize: '14pt', }}>Clinics</p>
                    <p onClick={() => setOpen(true)} style={{ fontWeight: '600', cursor: 'pointer', }}>
                        Add Clinic +
                    </p>
                </div>
                <div className="resiliancy-table">
                    <table className="table table-borderless">
                    {clinics.map((obj) => {
                        return (
                            <tr>
                                <td>
                                    <div style={{fontSize: '16pt', cursor: 'pointer' }} onClick={() => editClinic(obj)}>
                                        <p >{obj.name}</p>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </table>
                </div>
                <div className="resiliancy-table">
                    <div className="resiliancyHeader">
                        <p style={{fontWeight: '500',fontSize: '14pt', }}>Group Sessions</p>
                        <p onClick={() => setOpenGroupSession(true)} style={{ fontWeight: '600', cursor: 'pointer', }}>
                            Add Group Sessions +
                        </p>
                    </div>
                    <table className="table table-borderless">
                        {groupSessions.map((obj) => {
                            return (
                                <tr>
                                    <td>
                                        <p onClick={() => {
                                            handleEditGroupSession(obj)
                                        }} style={{fontSize: '16pt', cursor: 'pointer' }}>{obj.name}</p>
                                    </td>
                                </tr>                                    
                            )
                        })}
                    </table>
                </div>
                <div className="resiliancy-table">
                    <div className="resiliancyHeader">
                        <p style={{fontWeight: '500',fontSize: '14pt', }}>Services</p>
                        <p onClick={() => setOpenService(true)} style={{ fontWeight: '600', cursor: 'pointer', }}>
                            Add Service +
                        </p>
                    </div>
                    <table className="table table-borderless">

                        {/* Map services */}
                        {services.map((obj, index) => {
                            return (
                                <tr>
                                    <td>
                                    <p onClick={() => {
                                            getSingleServiceInfo(obj.id)
                                        }} 
                                        style={{fontSize: '16pt', cursor: 'pointer' }}>
                                        {obj.name}
                                    </p>
                                    </td>
                                </tr>                                    
                            )
                        })}
                    </table>
                </div>
            {/* ADD CLINIC MODAL */}
            {open ? 
                <Formik
                initialValues={{
                    name: '',
                    address: '',
                    email: '',
                    departments: [],
                }}
                onSubmit={(values) => handleAddClinic(values)}
                validationSchema={clinicValidation}>
                {(formikProps) => (
                <Modal
                show={open}
                onHide={() => setOpen(false)}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addWordForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Clinic</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Clinic Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Clinic Address
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('address')}
                                onBlur={formikProps.handleBlur('address')}
                                value={formikProps.values.address}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic address"
                            />
                            <ErrorMessage name="address" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Clinic Email Address
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('email')}
                                onBlur={formikProps.handleBlur('email')}
                                value={formikProps.values.email}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic email address"
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                        <div style={{flexDirection: 'row', paddingTop: '16px', paddingBottom: '16px',}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', top: '0.3rem'}}
                            >
                                Add Photos
                            </InputLabel>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {newClinicPhotos ? 
                                <div>
                                <img 
                                    alt="culture"
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        zIndex: 4,
                                        height: '9.75rem',
                                        width: '9.75rem',
                                    }} 
                                    src={newClinicPhotos.url}
                                />
                                <button type="button" onClick={() => { setnewClinicPhotos(null); formikProps.setFieldValue('photo', '')}}>
                                Remove
                                </button>
                                </div>
                            :  
                                <IconButton style={{
                                    position: 'relative', 
                                    border: '52px solid #3B7D7D', 
                                    backgroundColor: '#3B7D7D', 
                                    borderRadius: 0
                                }} 
                                    onClick={() => document.getElementById('upload-photo-add').click()}
                                >
                                    <MdFileUpload color="#FFF" />
                                </IconButton>
                            }
                        </div>
                        <input type='file' hidden name="photo" id="upload-photo-add" accept='image/*' onChange={(e) => {
                                const fr = new FileReader();
                                const file = e.target.files[0];
                                fr.onload = (ev) => {
                                    setnewClinicPhotos({url: fr.result, file: file}); 
                                }
                                fr.readAsDataURL(file);
                            }}
                        />
                        {/* Departments Block */}
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{flexDirection: 'row', color:"#000", fontSize:16, fontWeight: 'bold', }}
                            >
                                Departments
                            </InputLabel>
                            <FieldArray
                                name="departments"
                                render={arrayHelpers => (
                                <>
                                { formikProps.values.departments.length > 0 && formikProps.values.departments.map( (dep, index) => (
                                <div key={`departments_${index}`}>
                                <div style={{paddingBottom:  '16px'}}>
                                    <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                >
                                    Department Name
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange(`departments.${index}.name`)}
                                    onBlur={formikProps.handleBlur(`departments.${index}.name`)}
                                    value={formikProps.values.departments[index].name}
                                    size="small"
                                    style={{ 
                                        width: '100%',
                                        position: 'relative',
                                    }}
                                    placeholder="Enter Department Name"
                                />
                                <ErrorMessage name={`departments[${index}].name`} component="div" className="text-danger" />
                                </div>
                                <div style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between',}}>
                                    <div style={{paddingBottom:  '16px'}}>
                                    <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Phone Number 1
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments[${index}].phone1`)}
                                        onBlur={formikProps.handleBlur(`departments[${index}].phone1`)}
                                        value={formikProps.values.departments[index].phone1}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Phone Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone1`} component="div" className="text-danger" />
                                    </div>
                                    <div style={{paddingBottom:  '16px'}}>
                                        <InputLabel 
                                        InputLabelProps={{shrink: false, color: '#FFF'}}
                                        style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Extension 1
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments.${index}.phone1Extension`)}
                                        onBlur={formikProps.handleBlur(`departments.${index}.phone1Extension`)}
                                        value={formikProps.values.departments[index].phone1Extension}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Extension Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone1Extension`} component="div" className="text-danger" />
                                    </div>
                                </div>
                                <div style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{paddingBottom:  '16px'}}>
                                    <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Phone Number 2
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments[${index}].phone2`)}
                                        onBlur={formikProps.handleBlur(`departments[${index}].phone2`)}
                                        value={formikProps.values.departments[index].phone2}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Phone Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone2`} component="div" className="text-danger" />
                                    </div>
                                    <div style={{paddingBottom:  '16px'}}>
                                        <InputLabel 
                                        InputLabelProps={{shrink: false, color: '#FFF'}}
                                        style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative' }}
                                    >
                                        Extension 2
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments[${index}].phone2Extension`)}
                                        onBlur={formikProps.handleBlur(`departments[${index}].phone2Extension`)}
                                        value={formikProps.values.departments[index].phone2Extension}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Extension Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone2Extension`} component="div" className="text-danger" />
                                    </div>
                                </div>
                                <button style={{flexDirection: 'row', justifySelf: 'center'}} type="button" className="delete-word-button" onClick={() => arrayHelpers.remove(index)}>
                                    Remove Department
                                </button>
                                <hr/>
                                </div>
                                ))}
                            <button type="button" style={{width: '340px', }} className="add-word-button" onClick={() => arrayHelpers.push({name: '', phone1: '', phone1Extension: '', phone2: '', phone2Extension: '' })}>
                                Add New Department
                            </button>
                        </>
                        )}/>
                        
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Add Clinic
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
            {/* EDIT CLINIC MODAL */}
            {openEdit ? 
                <Formik
                initialValues={{
                    name: clinicNameToEdit,
                    address: clinicAddressToEdit,
                    email: clinicEmailToEdit,
                    departments: clinicDepartmentsToEdit ?? [],
                }}
                onSubmit={(values) => handleEditClinic(values)}
                validationSchema={clinicValidation}>
                {(formikProps) => (
                <Modal
                show={openEdit}
                onHide={() => setOpenEdit(false)}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addWordForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Clinic</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Clinic Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Clinic Address
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('address')}
                                onBlur={formikProps.handleBlur('address')}
                                value={formikProps.values.address}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic address"
                            />
                            <ErrorMessage name="address" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Clinic Email Address
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('email')}
                                onBlur={formikProps.handleBlur('email')}
                                value={formikProps.values.email}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic email address"
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                        <div style={{flexDirection: 'row', paddingTop: '16px', paddingBottom: '16px',}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', top: '0.3rem'}}
                            >
                                Add Photos
                            </InputLabel>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {newClinicPhotos ? 
                                <div>
                                <img 
                                    alt="culture"
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        zIndex: 4,
                                        height: '9.75rem',
                                        width: '9.75rem',
                                    }} 
                                    src={newClinicPhotos.url}
                                />
                                <button type="button" onClick={() => { setnewClinicPhotos(null); formikProps.setFieldValue('photo', '')}}>
                                Remove
                                </button>
                                </div>
                            :  
                                <IconButton style={{
                                    position: 'relative', 
                                    border: '52px solid #3B7D7D', 
                                    backgroundColor: '#3B7D7D', 
                                    borderRadius: 0
                                }} 
                                    onClick={() => document.getElementById('upload-photo-add').click()}
                                >
                                    <MdFileUpload color="#FFF" />
                                </IconButton>
                            }
                        </div>
                        <input type='file' hidden name="photo" id="upload-photo-add" accept='image/*' onChange={(e) => {
                                const fr = new FileReader();
                                const file = e.target.files[0];
                                fr.onload = (ev) => {
                                    setnewClinicPhotos({url: fr.result, file: file}); 
                                }
                                fr.readAsDataURL(file);
                            }}
                        />
                        {/* Departments Block */}
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{flexDirection: 'row', color:"#000", fontSize:16, fontWeight: 'bold', }}
                            >
                                Departments
                            </InputLabel>
                            <FieldArray
                                name="departments"
                                render={arrayHelpers => (
                                <>
                                { formikProps.values.departments.length > 0 && formikProps.values.departments.map( (dep, index) => (
                                <div key={`departments_${index}`}>
                                <div style={{paddingBottom:  '16px'}}>
                                    <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                >
                                    Department Name
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange(`departments.${index}.name`)}
                                    onBlur={formikProps.handleBlur(`departments.${index}.name`)}
                                    value={formikProps.values.departments[index].name}
                                    size="small"
                                    style={{ 
                                        width: '100%',
                                        position: 'relative',
                                    }}
                                    placeholder="Enter Department Name"
                                />
                                <ErrorMessage name={`departments[${index}].name`} component="div" className="text-danger" />
                                </div>
                                <div style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between',}}>
                                    <div style={{paddingBottom:  '16px'}}>
                                    <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Phone Number 1
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments[${index}].phone1`)}
                                        onBlur={formikProps.handleBlur(`departments[${index}].phone1`)}
                                        value={formikProps.values.departments[index].phone1}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Phone Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone1`} component="div" className="text-danger" />
                                    </div>
                                    <div style={{paddingBottom:  '16px'}}>
                                        <InputLabel 
                                        InputLabelProps={{shrink: false, color: '#FFF'}}
                                        style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Extension 1
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments.${index}.phone1Extension`)}
                                        onBlur={formikProps.handleBlur(`departments.${index}.phone1Extension`)}
                                        value={formikProps.values.departments[index].phone1Extension}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Extension Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone1Extension`} component="div" className="text-danger" />
                                    </div>
                                </div>
                                <div style={{flexDirection: 'row', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{paddingBottom:  '16px'}}>
                                    <InputLabel 
                                    InputLabelProps={{shrink: false, color: '#FFF'}}
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Phone Number 2
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments[${index}].phone2`)}
                                        onBlur={formikProps.handleBlur(`departments[${index}].phone2`)}
                                        value={formikProps.values.departments[index].phone2}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Phone Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone2`} component="div" className="text-danger" />
                                    </div>
                                    <div style={{paddingBottom:  '16px'}}>
                                        <InputLabel 
                                        InputLabelProps={{shrink: false, color: '#FFF'}}
                                        style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative' }}
                                    >
                                        Extension 2
                                    </InputLabel>
                                    <TextField 
                                        onChange={formikProps.handleChange(`departments[${index}].phone2Extension`)}
                                        onBlur={formikProps.handleBlur(`departments[${index}].phone2Extension`)}
                                        value={formikProps.values.departments[index].phone2Extension}
                                        size="small"
                                        style={{ 
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        placeholder="Enter Extension Number"
                                    />
                                    <ErrorMessage name={`departments[${index}].phone2Extension`} component="div" className="text-danger" />
                                    </div>
                                </div>
                                <button style={{flexDirection: 'row', justifySelf: 'center'}} type="button" className="delete-word-button" onClick={() => arrayHelpers.remove(index)}>
                                    Remove Department
                                </button>
                                <hr/>
                                </div>
                                ))}
                            <button type="button" style={{width: '340px', }} className="add-word-button" onClick={() => arrayHelpers.push({name: '', phone1: '', phone1Extension: '', phone2: '', phone2Extension: '' })}>
                                Add New Department
                            </button>
                        </>
                        )}/>
                        
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Save Clinic
                        </button>
                        <button style={{flexDirection: 'row', justifySelf: 'center'}} type="button" className="delete-word-button" onClick={deleteClinic}>
                            Delete Clinic
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
            {/* ADD SERVICE MODAL */}
            {openService ? 
                <Formik
                initialValues={{
                    name: '',
                    clinic: '',
                    who: '',
                    awards: '',
                    offer: '',
                    newPhotoCount: 0,
                }}
                onSubmit={(values) => handleAddService(values)}
                validationSchema={serviceValidation}>
                {(formikProps) => (
                <Modal
                show={openService}
                onHide={handleCloseAddService}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addServiceForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Service Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Clinic
                            </InputLabel>
                            <Select
                                value={formikProps.values.clinic}
                                onChange={formikProps.handleChange}
                                placeholder="Select Clinic"
                                style={{minWidth: '100%'}}
                                name="clinic"
                            >
                                <MenuItem value="" disabled>Select Clinic</MenuItem>
                            { clinics && clinics.map( (clinic, index) => (
                                <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
                                )
                            )}
                            </Select>
                            <ErrorMessage name="clinic" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Who We Are
                            </InputLabel>
                            <TextField 
                                multiline
                                onChange={formikProps.handleChange('who')}
                                onBlur={formikProps.handleBlur('who')}
                                value={formikProps.values.who}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '30px',
                                    position: 'relative',
                                }}
                                placeholder="Enter short bio"
                            />
                            <ErrorMessage name="who" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Our Awards and Acheivements
                            </InputLabel>
                            <TextField
                                multiline
                                onChange={formikProps.handleChange('awards')}
                                onBlur={formikProps.handleBlur('awards')}
                                value={formikProps.values.awards}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '30px',
                                    position: 'relative',
                                }}
                                placeholder="Enter list of achievements"
                            />
                            <ErrorMessage name="awards" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                What we Offer
                            </InputLabel>
                            <TextField
                                multiline
                                onChange={formikProps.handleChange('offer')}
                                onBlur={formikProps.handleBlur('offer')}
                                value={formikProps.values.offer}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '30px',
                                    position: 'relative',
                                }}
                                placeholder="Enter list of achievements"
                            />
                            <ErrorMessage name="offer" component="div" className="text-danger" />
                        </div>
                        <div style={{flexDirection: 'row', paddingTop: '16px', paddingBottom: '16px',}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', top: '0.3rem'}}
                            >
                                Add Photos
                            </InputLabel>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {newServicePhotos.length > 0 ? 
                                <div>
                                <img 
                                    alt="culture"
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        zIndex: 4,
                                        height: '9.75rem',
                                        width: '9.75rem',
                                    }} 
                                    src={newServicePhotos[0].url ?? newServicePhotos[0]?.photo ?? null}
                                />
                                <button type="button" onClick={() => { handleNewRemovePhoto(newServicePhotos[0].id ?? -1); formikProps.setFieldValue('photo', '')}}>
                                Remove
                                </button>
                                </div>
                            :  
                                <IconButton style={{
                                    position: 'relative', 
                                    border: '52px solid #3B7D7D', 
                                    backgroundColor: '#3B7D7D', 
                                    borderRadius: 0
                                }} 
                                    onClick={() => document.getElementById('upload-photo-add').click()}
                                >
                                    <MdFileUpload color="#FFF" />
                                </IconButton>
                            }
                        </div>
                        <input type='file' hidden name="photo" id="upload-photo-add" accept='image/*' onChange={(e) => {
                                const fr = new FileReader();
                                const file = e.target.files[0];
                                fr.onload = (ev) => {
                                    formikProps.setFieldValue('newPhotoCount', formikProps.values.newPhotoCount+1)
                                    setNewServicePhotos(prev => {
                                        let newArr = [{url: fr.result, file: file, id: -1 * formikProps.values.newPhotoCount+1}];
                                        return [...prev, ...newArr];
                                    }); 
                                }
                                fr.readAsDataURL(file);
                            }}
                        />
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Add Service
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
            {/* Edit SERVICE MODAL */}
            {openEditService ? 
                <Formik
                initialValues={{
                    name: serviceNameToEdit,
                    clinic: serviceClinicIdToEdit,
                    who: serviceDescriptionToEdit,
                    awards: serviceAwardsToEdit,
                    offer: serviceOfferingsToEdit,
                    newPhotoCount: 0,
                }}
                onSubmit={(values) => handleEditService(values)}
                validationSchema={serviceValidation}>
                {(formikProps) => (
                <Modal
                show={openEditService}
                onHide={handleCloseEditService}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addServiceForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Service Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter clinic name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Select Clinic
                            </InputLabel>
                            <Select
                                value={formikProps.values.clinic}
                                onChange={formikProps.handleChange}
                                style={{minWidth: '100%'}}
                                name="clinic"
                            >
                                <MenuItem value="" disabled>Select Clinic</MenuItem>
                            { clinics && clinics.map( (clinic, index) => (
                                <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
                                )
                            )}
                            </Select>
                            <ErrorMessage name="clinic" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Who We Are
                            </InputLabel>
                            <TextField 
                                multiline
                                onChange={formikProps.handleChange('who')}
                                onBlur={formikProps.handleBlur('who')}
                                value={formikProps.values.who}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '30px',
                                    position: 'relative',
                                }}
                                placeholder="Enter short bio"
                            />
                            <ErrorMessage name="who" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Our Awards and Acheivements
                            </InputLabel>
                            <TextField
                                multiline
                                onChange={formikProps.handleChange('awards')}
                                onBlur={formikProps.handleBlur('awards')}
                                value={formikProps.values.awards}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '30px',
                                    position: 'relative',
                                }}
                                placeholder="Enter list of achievements"
                            />
                            <ErrorMessage name="awards" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                What we Offer
                            </InputLabel>
                            <TextField
                                multiline
                                onChange={formikProps.handleChange('offer')}
                                onBlur={formikProps.handleBlur('offer')}
                                value={formikProps.values.offer}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '30px',
                                    position: 'relative',
                                }}
                                placeholder="Enter list of achievements"
                            />
                            <ErrorMessage name="offer" component="div" className="text-danger" />
                        </div>
                        <div style={{flexDirection: 'row', paddingTop: '16px', paddingBottom: '16px',}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', top: '0.3rem'}}
                            >
                                Add Photos
                            </InputLabel>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {servicePhotosToEdit.length > 0 ? 
                                <div>
                                <img 
                                    alt="culture"
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        zIndex: 4,
                                        height: '9.75rem',
                                        width: '9.75rem',
                                    }} 
                                    src={servicePhotosToEdit[0].url ?? servicePhotosToEdit[0]?.photo ?? null}
                                />
                                <button type="button" onClick={() => { handleEditRemovePhoto(servicePhotosToEdit[0].id ?? -1); formikProps.setFieldValue('photo', '')}}>
                                Remove
                                </button>
                                </div>
                            :  
                                <IconButton style={{
                                    position: 'relative', 
                                    border: '52px solid #3B7D7D', 
                                    backgroundColor: '#3B7D7D', 
                                    borderRadius: 0
                                }} 
                                    onClick={() => document.getElementById('upload-photo-add').click()}
                                >
                                    <MdFileUpload color="#FFF" />
                                </IconButton>
                            }
                        </div>
                        <input type='file' hidden name="photo" id="upload-photo-add" accept='image/*' onChange={(e) => {
                                const fr = new FileReader();
                                const file = e.target.files[0];
                                fr.onload = (ev) => {
                                    setServicePhotosToEdit([{url: fr.result, file: file, id: (formikProps.values.newPhotoCount+1)*-1}]); 
                                    formikProps.setFieldValue('newPhotoCount', formikProps.values.newPhotoCount+1);
                                }
                                fr.readAsDataURL(file);
                            }}
                        />
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Save Service
                        </button>
                        <button type="button" style={{width: '340px', }} className="delete-word-button" onClick={handleDeleteService}>
                            Delete Service
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
            {/* Add Group Session */}
            {openGroupSession ? 
                <Formik
                initialValues={{
                    name: '',
                    clinic: '',
                    date: '',
                    startTime: '',
                    endTime: '',
                    description: '',
                }}
                onSubmit={(values) => handleAddGroupSession(values)}
                validationSchema={groupSessionValidation}>
                {(formikProps) => (
                <Modal
                show={openGroupSession}
                onHide={() => setOpenGroupSession(false)}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addServiceForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Group Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Group Session Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter Group Session Name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Clinic
                            </InputLabel>
                            <Select
                                value={formikProps.values.clinic}
                                onChange={formikProps.handleChange}
                                placeholder="Select Clinic"
                                style={{minWidth: '100%'}}
                                name="clinic"
                            >
                                <MenuItem value="" disabled>Select Clinic</MenuItem>
                            { clinics && clinics.map( (clinic, index) => (
                                <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
                                )
                            )}
                            </Select>
                            <ErrorMessage name="clinic" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                            <div style={{paddingRight: '16px', }}>
                                <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                Event Date
                                </InputLabel>
                                <TextField
                                    onChange={formikProps.handleChange('date')}
                                    onBlur={formikProps.handleBlur('date')}
                                    value={formikProps.values.date}
                                    style={{
                                        zIndex: 2, 
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                    type="date"
                                />
                                <ErrorMessage name="date" component="div" className="text-danger" />
                            </div>
                            <div style={{paddingRight: '16px', }}>
                                <InputLabel 
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                    Start Time
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('startTime')}
                                    onBlur={formikProps.handleBlur('startTime')}
                                    defaultValue={formikProps.values.startTime}
                                    style={{ 
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                    type="time"
                                    placeholder="hh:mm:ss (military time)"
                                />
                                <ErrorMessage name="startTime" component="div" className="text-danger" />
                            </div>
                            <div>
                                <InputLabel 
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                    End Time
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('endTime')}
                                    onBlur={formikProps.handleBlur('endTime')}
                                    defaultValue={formikProps.values.endTime}
                                    style={{ 
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                    type="time"
                                    placeholder="hh:mm:ss (military time)"
                                />
                                <ErrorMessage name="endTime" component="div" className="text-danger" />
                            </div>
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Description
                            </InputLabel>
                            <TextField
                                multiline
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '60px',
                                    position: 'relative',
                                }}
                                placeholder="Enter short bio"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Add Group Session
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
            {/* Edit Group Session */}
            {openEditGroupSession ? 
                <Formik
                initialValues={{
                    name: groupNameToEdit,
                    clinic: groupClinicToEdit,
                    date: groupDateToEdit,
                    startTime: groupStartTimeToEdit,
                    endTime: groupEndTimeToEdit,
                    description: groupDescriptionToEdit,
                }}
                onSubmit={(values) => handleSaveGroupSession(values)}
                validationSchema={groupSessionValidation}>
                {(formikProps) => (
                <Modal
                show={openEditGroupSession}
                onHide={() => setOpenEditGroupSession(false)}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addServiceForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Group Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div >
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Group Session Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter Group Session Name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Clinic
                            </InputLabel>
                            <Select
                                value={formikProps.values.clinic}
                                onChange={formikProps.handleChange}
                                placeholder="Select Clinic"
                                style={{minWidth: '100%'}}
                                name="clinic"
                            >
                                <MenuItem value="" disabled>Select Clinic</MenuItem>
                            { clinics && clinics.map( (clinic, index) => (
                                <MenuItem key={clinic.id} value={clinic.id}>{clinic.name}</MenuItem>
                                )
                            )}
                            </Select>
                            <ErrorMessage name="clinic" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                            <div style={{paddingRight: '16px', }}>
                                <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                Event Date
                                </InputLabel>
                                <TextField
                                    onChange={formikProps.handleChange('date')}
                                    onBlur={formikProps.handleBlur('date')}
                                    value={formikProps.values.date}
                                    style={{
                                        zIndex: 2, 
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                    type="date"
                                />
                                <ErrorMessage name="date" component="div" className="text-danger" />
                            </div>
                            <div style={{paddingRight: '16px', }}>
                                <InputLabel 
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                    Start Time
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('startTime')}
                                    onBlur={formikProps.handleBlur('startTime')}
                                    defaultValue={formikProps.values.startTime}
                                    style={{ 
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                    type="time"
                                    placeholder="hh:mm:ss (military time)"
                                />
                                <ErrorMessage name="startTime" component="div" className="text-danger" />
                            </div>
                            <div>
                                <InputLabel 
                                    style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                    End Time
                                </InputLabel>
                                <TextField 
                                    onChange={formikProps.handleChange('endTime')}
                                    onBlur={formikProps.handleBlur('endTime')}
                                    defaultValue={formikProps.values.endTime}
                                    style={{ 
                                        position: 'relative',
                                        width: '100%',
                                    }}
                                    type="time"
                                    placeholder="hh:mm:ss (military time)"
                                />
                                <ErrorMessage name="endTime" component="div" className="text-danger" />
                            </div>
                        </div>
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Description
                            </InputLabel>
                            <TextField
                                multiline
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                size="small"
                                style={{ 
                                    width: '100%',
                                    minHeight: '60px',
                                    position: 'relative',
                                }}
                                placeholder="Enter short bio"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-word-button" onClick={formikProps.handleSubmit}>
                            Save Group Session
                        </button>
                        <button style={{flexDirection: 'row', justifySelf: 'center'}} type="button" className="delete-word-button" onClick={deleteGroupSession}>
                            Delete Group Session
                        </button>
                    </Modal.Footer>
                </form>
                </Modal>
                )}
                </Formik>
            :
                null
            }
        </div>
    )
}
