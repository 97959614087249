import React from 'react';
import {
  BrowserRouter,
  Route,
  NavLink,
  Switch,
  Redirect
} from "react-router-dom";
import Dashboard from './Screens/Dashboard/Dashboard';
import Login from './Screens/Login/login';
import CultureHome from './Screens/Culture/CultureHome';
import CulturePage from './Screens/Culture/CulturePage';
import ResiliencyHome from './Screens/Resiliency/ResiliencyHome';
import './App.css';
import ResiliencyPage from './Screens/Resiliency/ResiliencyPage';
import TreatmentsHome from './Screens/Treatments/TreatmentsHome';
import TreatmentsPage from './Screens/Treatments/TreatmentsPage';
import EventsHome from './Screens/Events/EventsHome';
import InformationHome from './Screens/Information/InformationHome';
import AssessmentHome from './Screens/Assessments/AssessmentHome';
import AssessmentPage from './Screens/Assessments/AssessmentPage';
import AdminsHome from './Screens/Admins/AdminsHome';
import NotFound from './Screens/NotFound/NotFound';
//for side dashboard
import './Screens/Dashboard/Dashboard.css';
import image from './toiyabe-logo-only.png';
import LocalStorageService from './Services/LocalStorageService';
import { connect } from 'react-redux';
import ActionTypes from './Redux/ActionTypes';
import GuardedRoute from './Providers/GuardedRoute';
import ProviderHome from './Screens/Provider/ProviderHome';

const mapStateToProps  = (state) => {
  return {
    auth: state.user.token
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (payload) => dispatch({type: ActionTypes.SET_USER_TOKEN, payload: payload}),
    logout: () => dispatch({type: ActionTypes.USER_LOGOUT})
  }
}

class App extends React.Component {

  constructor(){
    super()
    this.state = {
      clicked: true,
    }
  }

  async componentDidMount(){
    let token = LocalStorageService.fetchToken();
    if(token) {
      this.props.setAuth(LocalStorageService.fetchToken());
    };
  }

  render() {
    let auth = this.props.auth;
    return (
        <div className="row h-100">
        <BrowserRouter>
        {auth && <>
        <nav className="navbar col-md-2 d-non d-md-block sidebar">
          <div className="sidebar-sticky" id="sticky-sidebar">
            <img className="logo" alt="logo" src={image} />

            <ul className="nav flex-column">
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/dashboard">Dashboard</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/culture">Culture</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/resiliency">Resiliency</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/treatments">Treatments</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/events">Events</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/information">Information</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/assessment">Assessment</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/admins">Admins</NavLink>
            </li>
            <li>
              <NavLink activeStyle={{color:'#FFF'}} className="nav-item links" to="/providers">Providers</NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => {
                  this.props.logout();
                  LocalStorageService.clearToken();
                  localStorage.removeItem('userToken');
                  this.setState({
                    clicked: false
                  })
                }}
                activeStyle={{color:'#AEBFBF'}}
                className="links"
                to="/">
                  Log Out
              </NavLink>
            </li>
            </ul>
          </div>
        </nav>
        </>}
        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
          <Switch>
            <GuardedRoute exact path="/dashboard" component={Dashboard} auth={auth}/>
            <GuardedRoute exact path="/culture" component={CultureHome} auth={auth}/>
            <GuardedRoute exact path="/culture/:id,:title" component={CulturePage} auth={auth}/>
            <GuardedRoute exact path="/resiliency" component={ResiliencyHome} auth={auth}/>
            <GuardedRoute exact path="/resiliency/:title,:id" component={ResiliencyPage} auth={auth}/>
            <GuardedRoute exact path="/treatments" component={TreatmentsHome} auth={auth}/>
            <GuardedRoute exact path="/treatments/:title,:id" component={TreatmentsPage} auth={auth}/>
            <GuardedRoute exact path="/events" component={EventsHome} auth={auth}/>
            <GuardedRoute exact path="/information" component={InformationHome} auth={auth}/>
            <GuardedRoute exact path="/assessment" component={AssessmentHome} auth={auth}/>
            <GuardedRoute exact path="/assessment/:id,:title" component={AssessmentPage} auth={auth}/>
            <GuardedRoute exact path="/admins" component={AdminsHome} auth={auth}/>
            <GuardedRoute exact path="/providers" component={ProviderHome} auth={auth}/>
            <Route
                exact
                path="/"
                render={() => {
                    return (
                      auth ?
                      <Redirect to="/dashboard" /> :
                      <Redirect to="/login" />
                    );
                }}
             />
            <Route exact path="/login" render={() => <Login auth={auth}/>} />
            {/* Last route for 404 Page */}
            <Route component={NotFound} />
          </Switch>
        </main>
        </BrowserRouter>
        </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
