import React, { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { TextField} from '@material-ui/core';
import { fetchToAPI, postJsonData } from '../commonItems/Fetch';
import Axios from 'axios';
import './Dashboard.css';
import LocalStorageServices from '../../Services/LocalStorageService';

export default function Dashboard() {
    const [startDate, setStartDate] = useState(new Date('2014-08-18T21:11:54'));
    const [endDate, setEndDate] = useState(new Date('2014-08-18T23:11:54'))
    const [active, setActive] = useState('');
    const [activesurveys, setActiveSurveys] = useState(null);
    const [events, setEvents] = useState([]);
    const [general, setGeneral] = useState([]);
    const [resources, setResources] = useState([]);
    const [geography, setGeography] = useState([]);
    const [loadedResults, setLoadedResults] = useState(true);
    const [bearerToken, setBearerToken] = useState();

    useEffect(() => {
        setBearerToken(LocalStorageServices.fetchToken());
    }, [])

    const handleDateChange = async (e) => {
        setLoadedResults(false);
        e.preventDefault();
        const response = await postJsonData('https://app.toiyabe.us/api/admin/home', {
            start_date: startDate,
            end_date: endDate
        });
        setEvents(response.data.events);
        setGeneral(response.data);
        setStartDate(null);
        setEndDate(null);
        setLoadedResults(true);
    };

    const filterDeactivesurveys = (title) => {
        const newArray = general.assessments.filter(obj => obj.name === title)[0];
        setActiveSurveys(newArray);
        setActive(title);
    };

    useEffect(() => {
        async function getGeneral(){
            const result = await fetchToAPI('https://app.toiyabe.us/api/admin/home');
            setGeneral(result.data);
        }

        async function getResources(){
            const result = await fetchToAPI('https://app.toiyabe.us/api/admin/analytic');
            setResources(result.data.data);
            const clinics = await fetchToAPI('https://app.toiyabe.us/api/admin/clinic');
            // Build Geography
            let clinicClicks = result.data.data.filter( obj => obj.resource === '\\App\\Clinic');
            let geographyResults = [];
            for(var key in clinics.data) {
                let foundClinic = clinicClicks.find(obj => obj.resource_id == key);
                geographyResults.push({name: clinics.data[key], clicks: foundClinic?.count ?? 0});
            }
            setGeography(geographyResults);
        }
        getGeneral();
        getResources();
    }, []);

    useEffect(() => {
        if(!activesurveys) {
            if(general.assessments?.length > 0) {
                console.log("Setting First Active Surveys",general.assessments[0] );
                setActive(general.assessments[0].name);
                setActiveSurveys(general.assessments[0]);
            }
        }
    }, [general])

    return (
        <div className="page">
                <>
                <div className="row" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <h3 className="col-md-2" style={{fontSize: '28px', fontWeight: 'bold'}}>Dashboard</h3>
                    <form className="col-md-10" noValidate style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <TextField
                            onChange={e => setStartDate(e.target.value)}
                            style={{zIndex: 2}}
                            label="Enter start date"
                            type="date"
                            className='date-picker-one'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startDate}
                            style={{paddingRight: '16px'}}
                        />
                        <TextField
                            onChange={e => setEndDate(e.target.value)}
                            style={{zIndex: 2, marginLeft: '16px'}}
                            label="Enter end date"
                            type="date"
                            className='date-picker-two'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={endDate}
                        />
                        {loadedResults ?
                            <button color="#3B7D7D" className='update' style={{ marginLeft: '32px'}} onClick={(e) => handleDateChange(e)}>
                                Update
                            </button>
                        :
                            <button className="buttonload">
                                <i className="fa fa-circle-o-notch fa-spin"></i>Loading
                            </button>
                        }
                    </form>
                </div>
                <div className="row" style={{marginTop: '32px', marginBottom: '16px', }}>
                    <Link to="/assessment" className='assessments-button col-md-2'
                        style={{marginRight: '16px',
                        marginLeft: '16px',
                        textDecoration: 'none',
                        color: '#FFF',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '14',
                        display: 'flex'
                    }}>
                        View Assessments
                    </Link>
                    <Link to="/information"  className='assessments-button col-md-2' style={{marginRight: '16px',
                        marginLeft: '16px',
                        textDecoration: 'none',
                        color: '#FFF',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '14',
                        display: 'flex'
                    }}>
                            View Information
                    </Link>
                    <Link to="/treatments"  className='assessments-button col-md-2' style={{marginLeft: '16px',
                        textDecoration: 'none',
                        color: '#FFF',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '14',
                        display: 'flex'
                    }}>
                        View Treatments
                    </Link>
                </div>
                <div className="row">
                    <h5 className="col-md-12">General</h5>
                </div>
                <hr className="header-line" style={{margin: 0}}/>
                <div className="row">
                    { general.stats ? Object.keys(general.stats).map(title => {
                        const stats = general.stats;
                        return (
                            <div key={"general_stat_"+title} className='general-assessments col-md-2' style={{justifyContent: 'center', alignItems: 'cemter', flexDirection: 'column'}}>
                                <h2 className='obj-number'>{stats[title]}</h2>
                                <h3 style={{fontSize: '15pt'}} className='obj-text'>{title[0].toUpperCase()}{title.slice(1,)}</h3>
                            </div>
                        )
                    }) : null }
                </div>

                <div className="row">
                    <div className="col-md-6" style={{paddingRight: "16px"}}>
                        <div className="row">
                            <h5 className="col-md-12">Geography</h5>
                        </div>
                        <hr className="header-line" style={{margin: 0}}/>
                        <div className="row">
                            <div className="col-md-12" style={{position: 'relative', overflowY: 'scroll', height: '300px', marginLeft: "16px", marginRight: "16px" }}>
                                <div>
                                    {geography ? geography.map((obj) => {
                                        return (
                                            <div key={`geographic_obj.title`} className="row boxs"
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    backgroundColor: '#FFF',
                                                    alignItems: 'center',
                                                    padding: '16px',
                                                    marginBottom: "8px",
                                                }}>
                                                <h5 className="obj-title">{obj.name}</h5>
                                                <h5 className="obj-value">{obj.clicks}</h5>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingLeft: "16px"}}>
                        <h5 className="col-md-12">Resources</h5>
                        <hr className="header-line" style={{margin: 0}}/>
                        <div className="col-md-12" style={{position: 'relative', overflowY: 'scroll', height: '300px', marginRight: "16px" }}>
                            <div style={{position: 'relative',}}>
                                {resources.length > 0 && resources.map((obj) => {
                                    if(obj.name) {
                                        return (
                                            <div key={"general_resources_"+obj.name} className="row boxs"
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    backgroundColor: '#FFF',
                                                    alignItems: 'center',
                                                    padding: '16px',
                                                    marginBottom: "8px",
                                                }}>
                                                <h5 className="obj-title">{obj.name}</h5>
                                                <h5 className="obj-value">{obj.count} clicks</h5>
                                            </div>
                                        )
                                    } else {
                                        return;
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <h5 className="col-md-12">Surveys</h5>
                </div>
                <hr className="header-line" style={{margin: 0}}/>
                <div className="row" style={{height: "80px", alignItems: 'center',}}>
                    { general.assessments ? general.assessments.map((obj) => {
                        return (
                            <button
                                key={`button_${obj.name}`}
                                style={{
                                    backgroundColor: active === obj.name ? "#F78059" : "#DACDC9",
                                    borderColor: active === obj.name ? "#F78059" : "#DACDC9",
                                }}
                                className="survey-titles"
                                value="title"
                                onClick={() => filterDeactivesurveys(obj.name)}
                            >
                                {obj.name}
                            </button>
                        )
                    })  : null}
                </div>
                <div className="row" style={{flexDirection: 'row'}}>
                    {active && Object.values(activesurveys?.scores).map(obj => {
                        return (
                            <div key={`survey_${obj.range}`} className='survery-scores col-md-2' style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                <h2>{obj.numberofassessments}</h2>
                                <p className="survey-range">{obj.range}</p>
                            </div>
                        )
                    })}
                </div>
            </>
        </div>
    )
}
