import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import CreateIcon from '@material-ui/icons/CreateOutlined';
import { TextField, InputLabel } from '@material-ui/core'
import Modal from 'react-bootstrap/Modal';
import { fetchToAPI, updatePhoto, postJsonData } from '../commonItems/Fetch';
import Axios from 'axios';
import './Culture.css';
import LocalStorageService from '../../Services/LocalStorageService';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';

const tribeValidation = yup.object().shape({
    name: yup.string()
        .label('Tribe Name')
        .required(),
});

const sectionHeaderValidation = yup.object().shape({
    description: yup.string()
        .label('Description')
        .required(),
});


export default function CultureHome() {
    const [cultures, setCultures] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editTribe, setEditTribe] = useState(false);
    const [bearerToken, setBearerToken] = useState();
    const [sectionHeader, setSectionHeader] = useState();

    const getCultures = async () => {
        const result = await fetchToAPI('https://app.toiyabe.us/api/admin/culture');
        setCultures(result.data);
    }

    const getSectionHeader = async () => {
        const result = await fetchToAPI('https://app.toiyabe.us/api/appinfo/culture');
        setSectionHeader(result.data ?? '');
    }

    const handleAddTribe = async (values) => {
        await postJsonData('https://app.toiyabe.us/api/admin/culture',
        {
            name: values.name,
        });
        setOpenAdd(false);
        getCultures();
    };

    const onhandleDeleteTribe = async (tribeId) => {
        const body = new FormData();
        body.append("_method", "delete");
        await updatePhoto(`https://app.toiyabe.us/api/admin/culture/${tribeId}`, body);
        setOpenEdit(false);
        getCultures();
    };

    const handleUpdateHeader = async (values) => {
        const body = new FormData();
        body.append("key", "culture");
        body.append("value", values.description);
        body.append("_method", "put");
        let result = await updatePhoto('https://app.toiyabe.us/api/admin/appinfo', body);
        getSectionHeader();
        alert(result.data.message);
    }

    useEffect(() => {
        getCultures();
        getSectionHeader();
    }, [])

    useEffect(() => {
        setBearerToken(LocalStorageService.fetchToken());
    },[])

    return (
        <div className="page">
            <div className="resiliancyHeader">
                <h3 className="culture-header" style={{fontSize: '28px', fontWeight: 'bold'}}>Culture</h3>
                <button onClick={() => setOpenAdd(true)}
                    style={{
                        color: '#FFF',
                        backgroundColor: '#3B7D7D',
                        fontSize: '12pt',
                        fontWeight: 'bold',
                        height: '48px',
                        border: '10px solid #3B7D7D',
                    }}
                >
                    Add +
                </button>
            </div>
            <div style={{borderBottom:'solid 2px #bababa', paddingBottom: '10px', marginBottom: '20px'}}>
                <Formik
                    initialValues={{
                        description: sectionHeader,
                    }}
                    enableReinitialize
                    onSubmit={(values) => handleUpdateHeader(values)}
                    validationSchema={sectionHeaderValidation}>
                    {(formikProps) => (
                    <div style={{display: 'flex', paddingTop: '16px', flexDirection: 'row'}}>
                        <div style={{width: '66%', }}>
                            <InputLabel
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Culture Description
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                multiline
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter culture section description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                        <div style={{marginLeft: 12, width: '33%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <button style={{minWidth: '100px'}} type="button" className="delete-tribe-button" onClick={formikProps.resetForm}>
                                Reset Text
                            </button>
                            <button style={{minWidth: '100px', marginTop: '16px'}} type="button" className="add-tribe-button" onClick={formikProps.handleSubmit}>
                                Save Text
                            </button>
                        </div>
                    </div>
                )}
                </Formik>
            </div>
            <div className="resiliancy-table">
                <table>
                    {cultures ? Object.keys(cultures).map((key, index) => {
                        const id = key;
                        const title = cultures[key];
                        return (
                            <tr key={key} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '16px', paddingRight: '16px', marginBottom: '8px'}}>
                                <Link style={{textDecoration: 'none', color: '#000'}} to={`/culture/${id},${title}`}>
                                    <div>{title}</div>
                                </Link>
                                <CreateIcon
                                    color="#000"
                                    style={{
                                        fontSize: 30,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => { setEditTribe({id: id, name: title}); setOpenEdit(true)}}
                                />
                            </tr>
                        )
                    }) : null}
                </table>
            </div>
            {/* ADD TRIBE MODAL */}
            <Formik
            initialValues={{
                name: '',
            }}
            onSubmit={(values) => handleAddTribe(values)}
            validationSchema={tribeValidation}>
            {(formikProps) => (
                <Modal
                show={openAdd}
                onHide={() => { formikProps.resetForm(); setOpenAdd(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addTribeForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Tribe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Tribe Name
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter tribe title"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="submit" style={{width: '340px', }} className="add-tribe-button">
                            Save Tribe
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>
            <Modal
            show={openEdit}
            onHide={() => setOpenEdit(false)}
            backdrop="static"
            keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Edit Tribe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{flexDirection: 'row'}}>
                    <div style={{paddingTop: '16px' }}>
                        <InputLabel
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                        >
                            Tribe Name
                        </InputLabel>
                        <TextField
                            value={editTribe.name}
                            size="small"
                            style={{
                                width: '100%',
                                position: 'relative',
                            }}
                            disabled
                            placeholder="Enter tribe title"
                        />
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: 'center'}}>
                    <button type="button" style={{width: '340px', }} className="delete-tribe-button" onClick={() => onhandleDeleteTribe(editTribe.id)}>
                        Delete Tribe
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
