import ActionTypes from './ActionTypes';

const initialState = {
    email: null,
    token: null,
}

function UserReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.USER_LOGIN:
            return {
                ...state,
                email: action.payload.email,
                token: action.payload.token,
            }
        case ActionTypes.USER_LOGOUT:
            return initialState;
        case ActionTypes.SET_USER_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        default:
            return state;
    }
}

export default UserReducer;