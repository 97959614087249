import React, { useState, useEffect } from 'react'
import CreateIcon from '@material-ui/icons/CreateOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputLabel } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { fetchToAPI, postApplicationJson, updatePhoto } from '../commonItems/Fetch';
import Modal from 'react-bootstrap/Modal';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';

const resiliencyValidation = yup.object().shape({
    name: yup.string()
        .label('Resiliency')
        .required(),
    description: yup.string()
        .label('Description')
        .required(),
});

const sectionHeaderValidation = yup.object().shape({
    description: yup.string()
        .label('Description')
        .required(),
}); 

export default function ResiliencyHome() {
    const [subject, setSubject] = useState(null);
    const [addResiliency, setAddResiliency] = useState(false);
    const [editResiliency, setEditResiliency] = useState(false); 
    const [resiliencyIDToEdit, setResiliencyIDToEdit] = useState();
    const [resiliencyTitleToEdit, setResiliencyTitleToEdit] = useState();
    const [resiliencyDescriptionToEdit, setResiliencyDescriptionToEdit] = useState();
    const [sectionHeader, setSectionHeader] = useState();

    const getSubjects = async () => {
        const result = await fetchToAPI('https://app.toiyabe.us/api/admin/trauma');
        setSubject(result.data);
    }

    const getSectionHeader = async () => {
        const result = await fetchToAPI('https://app.toiyabe.us/api/appinfo/trauma');
        setSectionHeader(result.data ?? '');
    }

    useEffect(() => {
        getSubjects();
        getSectionHeader();
    }, []);

    const handleAddResiliency = async (values) => {
        await postApplicationJson('https://app.toiyabe.us/api/admin/trauma', {
            name: values.name,
            description: values.description,
        })
        getSubjects();
        setAddResiliency(false);
    };

    const handleEditResiliency = async (values) => {
        let formData = new FormData();
        formData.append('_method', 'put');
        formData.append('name', values.name);
        formData.append('description', values.description);

        await updatePhoto(`https://app.toiyabe.us/api/admin/trauma/${resiliencyIDToEdit}`, formData);
        setEditResiliency(false);
    }

    const handleDeleteResiliency = async (values) => {
        let formData = new FormData();
        formData.append('_method', 'delete');

        let result = await updatePhoto(`https://app.toiyabe.us/api/admin/trauma/${resiliencyIDToEdit}`, formData);
        alert(result.data.message);
        getSubjects();
        setEditResiliency(false);
    }

    const prepareResiliencyForEdit = async (id) => {
        const result = await fetchToAPI(`https://app.toiyabe.us/api/admin/trauma/${id}`);

        console.log("prepareResiliencyForEdit",result);
        setResiliencyIDToEdit(id);
        setResiliencyTitleToEdit(result.data.name);
        setResiliencyDescriptionToEdit(result.data.description ?? '');
        setEditResiliency(true);
    }

    const handleUpdateHeader = async (values) => {
        const body = new FormData();
        body.append("key", "trauma");
        body.append("value", values.description);
        body.append("_method", "put");
        let result = await updatePhoto('https://app.toiyabe.us/api/admin/appinfo', body);
        getSectionHeader();
        alert(result.data.message);
    }

    return (
        <div className="page">
            <div className="resiliancyHeader" style={{}}>
                <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>Resiliency</h3>
                <button onClick={() => setAddResiliency(true)} 
                style={{    
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                }}>
                    Add +
                </button>
            </div>
            <div style={{borderBottom:'solid 2px #bababa', paddingBottom: '10px', marginBottom: '20px'}}>
                <Formik
                    initialValues={{
                        description: sectionHeader,
                    }}
                    enableReinitialize
                    onSubmit={(values) => handleUpdateHeader(values)}
                    validationSchema={sectionHeaderValidation}>
                    {(formikProps) => (
                    <div style={{display: 'flex', paddingTop: '16px', flexDirection: 'row'}}>
                        <div style={{width: '66%', }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Resiliency Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                multiline
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter resiliency section description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                        <div style={{marginLeft: 12, width: '33%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <button style={{minWidth: '100px'}} type="button" className="delete-tribe-button" onClick={formikProps.resetForm}>
                                Reset Text
                            </button>
                            <button style={{minWidth: '100px', marginTop: '16px'}} type="button" className="add-tribe-button" onClick={formikProps.handleSubmit}>
                                Save Text
                            </button>
                        </div>
                    </div>
                )}
                </Formik>
            </div>
            <div className="resiliancy-table">
                <table className="table table-borderless">
                    {subject ? Object.keys(subject).map((key, index) => {
                        const obj = subject[key]
                        return (
                            <tr key={key} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '16px', paddingRight: '16px', marginBottom: '8px'}}>
                                <Link style={{ textDecoration: 'none', color: '#000'}} to={`/resiliency/${obj.name},${obj.id}`}>
                                    <div>{obj.name}</div>
                                </Link>
                                <CreateIcon 
                                    color="#000"
                                    style={{ 
                                        fontSize: 30,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        prepareResiliencyForEdit(obj.id)
                                    }}
                                />
                            </tr>
                        )
                    }) : null}
                </table>
            </div>
            {/* Add Resiliency */}
            { addResiliency && (
            <Formik
            initialValues={{
                name: '',
                description: '',
            }}
            onSubmit={(values) => handleAddResiliency(values)}
            validationSchema={resiliencyValidation}>
            {(formikProps) => (
                <Modal
                show={addResiliency}
                onHide={() => { formikProps.resetForm(); setAddResiliency(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Resiliency</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Resiliency Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter resiliency title"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                multiline
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                    minHeight: '30px',
                                }}
                                placeholder="Enter a description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="submit" style={{width: '340px', }} className="add-tribe-button">
                            Save Resiliency
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>
            )}
            { editResiliency && (

            <Formik
            initialValues={{
                name: resiliencyTitleToEdit,
                description: resiliencyDescriptionToEdit,
            }}
            enableReinitialize
            onSubmit={(values) => handleEditResiliency(values)}
            validationSchema={resiliencyValidation}>
            {(formikProps) => (
                <Modal
                show={editResiliency}
                onHide={() => { formikProps.resetForm(); setEditResiliency(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Resiliency</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Resiliency Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter resiliency title"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                multiline
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter a description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Resiliency
                        </button>
                        <button type="button" style={{width: '340px', }} className="delete-tribe-button" onClick={handleDeleteResiliency}>
                            Delete Resiliency
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>
            )}
        </div>
    )
}
