import React, { useState, useEffect } from 'react'
import CreateIcon from '@material-ui/icons/CreateOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";
import { TextField, InputLabel } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop';
import { fetchToAPI, postApplicationJson, deleteItem } from '../commonItems/Fetch';
import Modal from 'react-bootstrap/Modal';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';

const assessmentValidation = yup.object().shape({
    name: yup.string()
        .required()
        .label('Assessment Name'),
});

export default function AssessmentHome() { 
    const [assessments, setAssessments] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [assessmentToEdit, setAssessmentToEdit] = useState('');
    const [idToEdit, setIdToEdit] = useState(0);
    const [openEdit, setOpenEdit] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    async function getAssessments(){
        const data = await fetchToAPI('https://app.toiyabe.us/api/admin/assessment');
        let assessments = Object.entries(data.data).map(([key, value]) => {
            return {id: key, name: value};
        });
        setAssessments(Object.values(assessments));
    }

    const handleAddAssessment = async (values) => {
        await postApplicationJson('https://app.toiyabe.us/api/admin/assessment', {
            name: values.name,
        });
        handleClose();
        getAssessments();
        setOpen(false);
    }

    const selectedEditAssessment = (title, id) => {
        setAssessmentToEdit(title);
        setIdToEdit(id);
        setOpenEdit(true);
    }

    const handleEditAssessment = async (values) => {
        await postApplicationJson(`https://app.toiyabe.us/api/admin/assessment/${idToEdit}`, {
            name: values.name,
            _method: 'put',
        });
        getAssessments();
        setOpenEdit(false);
    }

    const deleteAssessment = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/assessment/${idToEdit}`);
        getAssessments();
        setOpenEdit(false);
    }

    

    useEffect(() => {
        getAssessments();
    }, [])

    return (
        <div className="page">
            <div className="resiliancyHeader">
                <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>Assessment</h3>
                <button onClick={() => handleOpen(true)} 
                style={{    
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                    backgroundColor: '#3B7D7D',
                }}>
                    Add +
                </button>
            </div>  
            <div className="resiliancy-table">
                <table className="table table-borderless">

                    {assessments ? 
                        assessments.map((obj, index) => {
                            return (
                                <tr>
                                    <td>
                                    <Link className="titles" style={{float: "left", textDecoration: 'none', color: '#000', }} title={obj.name} to={`/assessment/${obj.id},${obj.name}`}>
                                        {obj.name}
                                    </Link>
                                    <CreateIcon 
                                        color="#000"
                                        style={{ fontSize: 35, float: "right", cursor: 'pointer'}}
                                        onClick={() => selectedEditAssessment(obj.name, obj.id)}
                                    />
                                    </td>
                                </tr>
                            )
                        })
                    : 
                        null
                    }
                </table>
            </div>
            {/* ADD Assessment MODAL */}
            <Formik
            initialValues={{
                name: '',
            }}
            onSubmit={(values) => handleAddAssessment(values)}
            validationSchema={assessmentValidation}>
            {(formikProps) => (
                <Modal
                show={open}
                onHide={() => { formikProps.resetForm(); setOpen(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Assessment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Assessment Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter name of assessment"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Assessment
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>
            {/* Edit Assessment MODAL */}
            <Formik
            initialValues={{
                name: assessmentToEdit,
            }}
            enableReinitialize
            onSubmit={(values) => handleEditAssessment(values)}
            validationSchema={assessmentValidation}>
            {(formikProps) => (
                <Modal
                show={openEdit}
                onHide={() => { formikProps.resetForm(); setOpenEdit(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Assessment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Assessment Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                                value={formikProps.values.name}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter name of assessment"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Assessment
                        </button>
                        <button style={{flexDirection: 'row', justifySelf: 'center'}} type="button" className="delete-word-button" onClick={deleteAssessment}>
                            Delete Assessment
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>
        </div>
    )
}
