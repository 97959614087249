import React, { useState, createRef, useEffect } from 'react'
import { MdFileUpload } from "react-icons/md";
import { TextField, InputLabel, IconButton } from '@material-ui/core'
import Dropzone from 'react-dropzone';
import ReactPlayer from 'react-player';
import { fetchToAPI, updatePhoto, deleteItem} from '../commonItems/Fetch';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import {Formik, ErrorMessage} from 'formik';
import * as yup from 'yup';
import Constants from '../../Constants';

const eventValidation = yup.object().shape({
    title: yup.string()
        .label('Title')
        .required(),
    description: yup.string()
        .label('Description')
        .required(),
    location: yup.string()
        .label('Location')
        .required(),
    eventDate: yup.string()
        .label('Event Date')
        .required(),
    eventStart: yup.string()
        .label('Event Start Time')
        .required(),
    eventEnd: yup.string()
        .label('Event End Time')
        .required(),
});

export default function EventsHome() { 
    const [events, setEvents] = useState();
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [eventToEdit, setEventToEdit] = useState(null);
    const [titleToEdit, setTitleToEdit] = useState(null);
    const [descriptionToEdit, setDescriptionToEdit] = useState(null);
    const [pdfToEdit, setPDFToEdit] = useState(null);
    const [photosToEdit, setPhotosToEdit] = useState([]);
    const [photosToDelete, setPhotosToDelete] = useState([]);
    const [videoToEdit, setVideoToEdit] = useState(null);
    const [locationToEdit, setLocationToEdit] = useState(null);
    const [startTimeToEdit, setStartTimeToEdit] = useState(null);
    const [endTimeToEdit, setEndTimeToEdit] = useState(null);
    const [dateToEdit, setDateToEdit] = useState(null);
    const [objectIDToEdit, setObjectIDToEdit] = useState(null);
    const dropzoneRef = createRef();
    const [controls, setControls] = useState({
        playing: false,
        url: '',
        controls: false
    });
    const [newCultureTitle, setNewCultureTitle] = useState(null);
    const [newCultureDescription, setNewCultureDescription] = useState(null);

    const [newCulturePhoto, setNewCulturePhoto] = useState(null);
    const [newCultureVideo, setNewCultureVideo] = useState(null);
    const [newCulturePDF, setNewCulturePDF] = useState(null);
    const [newCultureLocation, setNewCultureLocation] = useState(null);
    const [newCultureDate, setNewCultureDate] = useState(null);
    const [newCultureStartTime, setNewCultureStartTime] = useState(null);
    const [newCultureEndTime, setNewCultureEndTime] = useState(null);
    
    const getEvents = async () => {
        const results = await fetchToAPI('https://app.toiyabe.us/api/admin/event');
        setEvents(results.data.data);
    };

    useEffect(() => {
        getEvents();
    }, []);

    const getObjectToEdit = async (id) => {
        setObjectIDToEdit(id);
        const result = await fetchToAPI(`https://app.toiyabe.us/api/admin/event/${id}`);
        const item = result.data;
        setEventToEdit(item);
        setTitleToEdit(item.title)
        setDescriptionToEdit(item.description);
        setPDFToEdit(item.pdf ? {url: item.pdf, item: null} : null);
        setVideoToEdit(item.video ? {url: item.video, file: null} : null);

        // Photos are in an array, grab the first one.
        if(item.event_photos) {
            setPhotosToEdit(item.event_photos);
        } else {
            setPhotosToEdit([]);
        }
        setLocationToEdit(item.location);
        setStartTimeToEdit(moment(item.event_start_time, 'HH:mm:ss').format('HH:mm'));
        setEndTimeToEdit(moment(item.event_end_time, 'HH:mm:ss').format('HH:mm'));
        setDateToEdit(item.event_date);
    };
    
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleExistingEventClicked = async (eventId) => {
        getObjectToEdit(eventId).then(() => {
            setOpenEdit(true);
        }).catch(err => console.log("Failed with error", err));
    }

    const onImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const fr = new FileReader();
            const file = e.target.files[0];
            fr.onload = (e) => {
                setNewCulturePhoto({url: fr.result, file: file}); 
            };
            fr.readAsDataURL(file);
        }
    };

    const onVideoCultureChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const fr = new FileReader();
            const file = e.target.files[0];
            fr.onload = (e) => {
                setNewCultureVideo({url: e.target.result, file: file}); 
            };
            fr.readAsDataURL(file);
        }
    };

    const onVideoChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const fr = new FileReader();
            const file = e.target.files[0];
            fr.onload = (e) => {
                setVideoToEdit({url: e.target.result, file: file});
            };
            fr.readAsDataURL(file);
        }
    };

    const handlePause = () => {
        setControls({
            ...controls,
            playing: false
        })
    };

    const handlePlay = () => {
        setControls({
            ...controls,
            playing: true
        })
    };

    const handlePlayPause = () => {
        setControls({
            ...controls,
            playing: !controls.playing
        })
    };
    
    const handleStop = () => {
        setControls({
            ...controls,
            url: null,
            playing: false
        })
    };

    const handleAddEvent = async (values) => {
        const bodyFormData = new FormData();
        bodyFormData.append("title", values.title);
        bodyFormData.append("description", values.description);
        bodyFormData.append("location", values.location);
        bodyFormData.append("event_date", values.eventDate);
        bodyFormData.append("event_start_time", moment(values.eventStart, 'HH:mm').format('HH:mm:ss'));
        bodyFormData.append("event_end_time", moment(values.eventEnd, 'HH:mm').format('HH:mm:ss'));

        if(newCultureVideo) {
            bodyFormData.append("video", newCultureVideo.file);
        }
        if(newCulturePhoto) {
            bodyFormData.append("photos[0]", newCulturePhoto.file);
        }
        if(newCulturePDF) {
            bodyFormData.append("pdf", newCulturePDF.file);
        }

        await updatePhoto(`https://app.toiyabe.us/api/admin/event`, bodyFormData);
        getEvents();
        setOpen(false);
    };

    const handleEditEvent = async (values) => {
        //form data Patch
        const bodyFormData = new FormData();
        bodyFormData.append("title", values.title);
        bodyFormData.append("description", values.description);
        bodyFormData.append("location", values.location);
        bodyFormData.append("event_date", values.eventDate);
        bodyFormData.append("event_start_time", moment(values.eventStart, 'HH:mm').format('HH:mm:ss'));
        bodyFormData.append("event_end_time", moment(values.eventEnd, 'HH:mm').format('HH:mm:ss'));
        bodyFormData.append("_method", "PUT");

        if(values.hasVideo && !videoToEdit) {
            bodyFormData.append('deletevideo', 1);
        } else if (videoToEdit && videoToEdit.file){
            bodyFormData.append('video', videoToEdit.file);
        } else {
            bodyFormData.append('deletevideo', 0);
        }

        if(values.hasPdf && !pdfToEdit) {
            bodyFormData.append('deletepdf', 1);
        } else if (pdfToEdit && pdfToEdit.file){
            bodyFormData.append('pdf', pdfToEdit.file);
        } else {
            bodyFormData.append('deletepdf', 0);
        }

        if(photosToDelete.length > 0) {
            photosToDelete.map((photoToDelete, index) => {
                bodyFormData.append(`deletephotos[${index}]`, photoToDelete);
            });
        } 

        console.log('photosToEdit',photosToEdit);
        if(photosToEdit.length > 0) {
            console.log('photosToEdit - Length is greated than 0');
            photosToEdit.map( (photo, index) => {
                console.log('photosToEdit - Inside map', photo);
                if(photo.id < 0) {
                    console.log('photosToEdit - Body appended');
                    bodyFormData.append(`photos[${index}]`, photo.file);
                }
            })
        }

        await updatePhoto(`https://app.toiyabe.us/api/admin/event/${objectIDToEdit}`, bodyFormData);
        getEvents();
        handleCloseEditService()
    };

    const handleEditRemovePhoto = (id) => {
        if (id > 0) {
            let intArr = [id];
            setPhotosToDelete(prev => [...prev, ...intArr]);
        }
        setPhotosToEdit(prev => {
            let index = prev.findIndex( item => item.id === id);
            if(index >= 0) {
                return prev.splice(index, 1);
            } else {
                return prev;
            }
        })
    }

    const handleCloseEditService = () => {
        setOpenEdit(false);
        setPhotosToEdit([]);
        setPhotosToDelete([]);
    }

    const handleDeleteEvent = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/event/${objectIDToEdit}`);
        getEvents();
        handleCloseEditService();
    };

    return (
        <div className="page">
            <div className="resiliancyHeader">
                <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>Events</h3>
                <button onClick={() => setOpen(true)} 
                style={{    
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                    backgroundColor: '#3B7D7D',
                }}>
                    Add +
                </button>
            </div>            
            {events ? 
                <div className="resiliancy-table">
                    <table className="table table-borderless">
                    {events.map((obj) => {
                        return (
                            <tr key={obj}>
                                <td>
                                    <div>
                                    <div style={{float: 'left', cursor: 'pointer', }} onClick={() => handleExistingEventClicked(obj.id)} >
                                        {obj.title}
                                    </div>
                                    <div style={{float: 'right' }}className="clicks">{obj.clicks} clicks</div>
                                    </div>
                                </td>
                            </tr>

                        )
                    })}
                    </table>
                </div>
            : null}
            {/* ADD EVENT MODAL */}
            <Formik
            initialValues={{
                title: '',
                description: '',
                location: '',
                eventDate: '',
                eventStart: '',
                eventEnd: '',
            }}
            onSubmit={(values) => handleAddEvent(values)}
            validationSchema={eventValidation}>
            {(formikProps) => (
                <Modal
                show={open}
                onHide={() => { formikProps.resetForm(); setOpen(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Event Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('title')}
                                onBlur={formikProps.handleBlur('title')}
                                value={formikProps.values.title}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter title of event"
                            />
                            <ErrorMessage name="title" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Where
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('location')}
                                onBlur={formikProps.handleBlur('location')}
                                value={formikProps.values.location}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter location of event"
                            />
                            <ErrorMessage name="location" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                size="small"
                                multiline
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter meaning"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                            <InputLabel 
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Event Date
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('eventDate')}
                                onBlur={formikProps.handleBlur('eventDate')}
                                value={formikProps.values.eventDate}
                                style={{
                                    zIndex: 2, 
                                    position: 'relative',
                                    width: '100%',
                                }}
                                type="date"
                            />
                            <ErrorMessage name="eventDate" component="div" className="text-danger" />
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                Start Time
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('eventStart')}
                                onBlur={formikProps.handleBlur('eventStart')}
                                style={{ 
                                    position: 'relative',
                                    width: '100%',
                                }}
                                type="time"
                                placeholder="hh:mm:ss (military time)"
                            />
                            <ErrorMessage name="eventStart" component="div" className="text-danger" />
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                End Time
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('eventEnd')}
                                onBlur={formikProps.handleBlur('eventEnd')}
                                style={{ 
                                    position: 'relative',
                                    width: '100%',
                                }}
                                type="time"
                                placeholder="hh:mm:ss (military time)"
                            />
                            <ErrorMessage name="eventEnd" component="div" className="text-danger" />
                        </div>
                        <div>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Video
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {newCultureVideo ? 
                                        <div style={{flexDirection: 'column', alignContent: 'center', justifyContent: 'center',}}>
                                            <ReactPlayer 
                                            style={{maxHeight: '150px', maxWidth: '150px'}} 
                                            url={newCultureVideo.url} 
                                            playing={controls.playing}
                                            onPlay={handlePlay}
                                            controls={controls.controls}
                                            onPause={handlePause}
                                            />
                                            <div style={{flexDirection: 'row', }}>
                                                <button type="button" onClick={handleStop}>
                                                    Stop
                                                </button>
                                                <button type="button" onClick={handlePlayPause}>
                                                    {controls.playing ? 'Pause' : 'Play'}
                                                </button>
                                            </div>
                                            <button type="button" className="delete-word-button" onClick={() => { setNewCultureVideo(null);}}>
                                                Remove Video
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('event-upload-video-add').click()}  style={{zIndex: 5, position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} onClick={(e) => onVideoCultureChange(e)} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept={Constants.ACCEPTED_VIDEO_TYPES} hidden name="video1" id="event-upload-video-add" onChange={(e) => {
                                        onVideoCultureChange(e)
                                    }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Photo
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {newCulturePhoto ? 
                                        <div style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                            <img src={newCulturePhoto.url} style={{position: 'relative', height: '188px', width: '200px' }} />
                                            <button type="button" className="delete-word-button" onClick={() => { setNewCulturePhoto(null);}}>
                                                Remove Image
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('course-upload-image-add').click()}  style={{position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} onClick={() => document.getElementById('course-upload-image-add').click()} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept="image/*" hidden name="image" id="course-upload-image-add" onChange={(e) => {
                                        const fr = new FileReader();
                                        const file = e.target.files[0];
                                        fr.onload = (ev) => {
                                            setNewCulturePhoto({url: fr.result, file: file}); 
                                        };
                                        fr.readAsDataURL(file);
                                        }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            PDF
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {newCulturePDF ? 
                                        <div style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                            <p style={{position: 'relative', fontSize: '18pt'}}>{newCulturePDF.file.name}</p>
                                            <button type="button" className="delete-word-button" onClick={() => { setNewCulturePDF(null);}}>
                                                Remove PDF
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('course-upload-pdf-add').click()} style={{position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept="application/pdf" hidden name="pdf" id="course-upload-pdf-add" onChange={(e) => {
                                        const fr = new FileReader();
                                        let file = e.target.files[0];
                                        setNewCulturePDF({url: file.name, file: file}); 
                                        fr.readAsDataURL(file);
                                        }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Event
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>

            {/* EDIT EVENT MODAL */}
            { openEdit && (

            <Formik
            initialValues={{
                title: titleToEdit,
                description: descriptionToEdit,
                location: locationToEdit,
                eventDate: dateToEdit,
                eventStart: startTimeToEdit,
                eventEnd: endTimeToEdit,
                photoCount: 0,
                hasPdf: pdfToEdit,
                hasVideo: videoToEdit,
            }}
            onSubmit={(values) => handleEditEvent(values)}
            validationSchema={eventValidation}>
            {(formikProps) => (
                <Modal
                show={openEdit}
                onHide={() => { formikProps.resetForm(); handleCloseEditService()}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Event Name
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('title')}
                                onBlur={formikProps.handleBlur('title')}
                                value={formikProps.values.title}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter title of event"
                            />
                            <ErrorMessage name="title" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Where
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('location')}
                                onBlur={formikProps.handleBlur('location')}
                                value={formikProps.values.location}
                                size="small"
                                style={{ 
                                    width: '100%', 
                                    position: 'relative',
                                }}
                                placeholder="Enter location of event"
                            />
                            <ErrorMessage name="location" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px'}}>
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                            >
                                Description
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('description')}
                                onBlur={formikProps.handleBlur('description')}
                                value={formikProps.values.description}
                                size="small"
                                multiline
                                style={{ 
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter description"
                            />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                            <InputLabel 
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Event Date
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('eventDate')}
                                onBlur={formikProps.handleBlur('eventDate')}
                                value={formikProps.values.eventDate}
                                style={{
                                    zIndex: 2, 
                                    position: 'relative',
                                    width: '100%',
                                }}
                                type="date"
                            />
                            <ErrorMessage name="eventDate" component="div" className="text-danger" />
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                Start Time
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('eventStart')}
                                onBlur={formikProps.handleBlur('eventStart')}
                                defaultValue={formikProps.values.eventStart}
                                style={{ 
                                    position: 'relative',
                                    width: '100%',
                                }}
                                type="time"
                                placeholder="hh:mm:ss (military time)"
                            />
                            <ErrorMessage name="eventStart" component="div" className="text-danger" />
                            <InputLabel 
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                                End Time
                            </InputLabel>
                            <TextField 
                                onChange={formikProps.handleChange('eventEnd')}
                                onBlur={formikProps.handleBlur('eventEnd')}
                                defaultValue={formikProps.values.eventEnd}
                                style={{ 
                                    position: 'relative',
                                    width: '100%',
                                }}
                                type="time"
                                placeholder="hh:mm:ss (military time)"
                            />
                            <ErrorMessage name="eventEnd" component="div" className="text-danger" />
                        </div>
                        <div>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Video
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {videoToEdit ? 
                                        <div style={{flexDirection: 'column', alignContent: 'center', justifyContent: 'center',}}>
                                            <ReactPlayer 
                                            style={{maxHeight: '150px', maxWidth: '150px'}} 
                                            url={videoToEdit.url} 
                                            playing={controls.playing}
                                            onPlay={handlePlay}
                                            controls={controls.controls}
                                            onPause={handlePause}
                                            />
                                            <div style={{flexDirection: 'row', }}>
                                                <button type="button" onClick={handleStop}>
                                                    Stop
                                                </button>
                                                <button type="button" onClick={handlePlayPause}>
                                                    {controls.playing ? 'Pause' : 'Play'}
                                                </button>
                                            </div>
                                            <button type="button" className="delete-word-button" onClick={() => { setVideoToEdit(null);}}>
                                                Remove Video
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('upload-video-edit').click()}  style={{zIndex: 5, position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} onClick={(e) => onVideoChange(e)} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept={Constants.ACCEPTED_VIDEO_TYPES} hidden name="video2" id="upload-video-edit" onChange={(e) => {
                                        onVideoChange(e);
                                    }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            Photo
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {photosToEdit.length > 0 ? 
                                        <div style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                            <img src={photosToEdit[0].url ?? photosToEdit[0].photo ?? null} style={{position: 'relative', height: '188px', width: '200px' }} />
                                            <button type="button" className="delete-word-button" onClick={() => { handleEditRemovePhoto(photosToEdit[0].id);}}>
                                                Remove Image
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('course-upload-image-edit').click()}  style={{position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} onClick={() => document.getElementById('course-upload-image-edit').click()} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept="image/*" hidden name="image" id="course-upload-image-edit" onChange={(e) => {
                                        const fr = new FileReader();
                                        const file = e.target.files[0];
                                        fr.onload = (ev) => {
                                            let newCount = formikProps.values.photoCount+1
                                            setPhotosToEdit([{url: fr.result, file: file, id: -1*newCount}]); 
                                            formikProps.setFieldValue('photoCount', newCount);
                                        };
                                        fr.readAsDataURL(file);
                                        }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        <InputLabel 
                            InputLabelProps={{shrink: false, color: '#FFF'}}
                            style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', paddingTop: '16px'}}>
                            PDF
                        </InputLabel>
                        <Dropzone ref={dropzoneRef} noClick noKeyboard>
                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                return (
                                <div className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    {pdfToEdit ? 
                                        <div style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                                            <p style={{position: 'relative', fontSize: '12pt'}}>{pdfToEdit.url}</p>
                                            <button type="button" className="delete-word-button" onClick={() => { setPDFToEdit(null);}}>
                                                Remove PDF
                                            </button>
                                        </div>
                                    : 
                                        <IconButton onClick={() => document.getElementById('course-upload-pdf-edit').click()} style={{position: 'relative', border: '62px solid #3B7D7D', backgroundColor: '#3B7D7D', borderRadius: 0}}>
                                            <MdFileUpload size={40} color="#FFF" />
                                        </IconButton>
                                    }
                                    </div>
                                    <input type='file' accept="application/pdf" hidden name="pdf" id="course-upload-pdf-edit" onChange={(e) => {
                                        const fr = new FileReader();
                                        let file = e.target.files[0];
                                        setPDFToEdit({url: file.name, file: file}); 
                                        fr.readAsDataURL(file);
                                        }}
                                    />
                                </div>
                                );
                            }}
                        </Dropzone>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Event
                        </button>
                        <button type="button" style={{width: '340px', }} className="delete-tribe-button" onClick={handleDeleteEvent}>
                            Delete Event
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>
            )}
            
        </div>
    )
}
