import React, { useState, useEffect } from 'react'
import { TextField, InputLabel, Select, MenuItem } from '@material-ui/core';
import { fetchToAPI, putApplicationJson, postApplicationJson, deleteItem } from '../commonItems/Fetch';
import './Assessment.css';
import Modal from 'react-bootstrap/Modal';
import FormCheck from 'react-bootstrap/FormCheck';
import {Formik, ErrorMessage, FieldArray} from 'formik';
import * as yup from 'yup';

export default function AssessmentPage(props) {
    const title = props.match.params.title;
    const id = props.match.params.id;
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openCondition, setOpenCondition] = useState(false);
    const [openEditCondition, setOpenEditCondition] = useState(false);
    const [assQuestions, setAssQuestions] = useState([])
    const [assConditions, setAssConditions] = useState([])
    const [questionIdToEdit, setQuestionIdToEdit] = useState();
    const [conditionToEdit, setConditionToEdit] = useState([{id: 0,score: 0, show: '', display: ''}]);
    const [assQuestionToEdit, setAssQuestionToEdit] = useState('');
    const [assAnswersToEdit, setAssAnswersToEdit] = useState([]);

    const answerValidation = yup.object().shape({
        id: yup.string()
            .nullable(),
        answer: yup.string()
            .required()
            .label('Answer'),
        correct: yup.bool()
            .required()
            .label('Correct'),
    });

    const questionValidation = yup.object().shape({
        question: yup.string()
            .required()
            .label('Question'),
        answers: yup.array().of(answerValidation)
            .min(1)
    });

    const conditionValidation = yup.object().shape({
        condition: yup.string()
            .required()
            .label('Condition'),
        score: yup.number()
            .required()
            .typeError("Scoring must be a whole number")
            .integer("Scoring must be a whole number")
            .label("Scoring"),
        show: yup.string()
            .required()
            .label('Show'),
        display: yup.string()
            .required()
            .label('Display'),
    });

    const handleAddQuestion = async (values) => {
        //application/json POST
        //question
        //array of answers with answer & correct
        const result = await postApplicationJson(`https://app.toiyabe.us/api/admin/assessment/${id}/question`, {
            question: values.question,
            answer: values.answers.map(item => {
                return {
                    answer: item.answer,
                    correct: item.correct ? 1 : 0,
                }
            })
        });
        alert(result.data.message);
        getAssessmentQuestions();
        setOpen(false);
    }

    const fetchEditQuestion = (question) => {
        setQuestionIdToEdit(question.id);
        setAssQuestionToEdit(question.question);
        setAssAnswersToEdit(question.assessment_question_answer ?? []);
        setOpenEdit(true);
    };

    const fetchEditCondition = (condition) => {
        setConditionToEdit(condition);
        setOpenEditCondition(true)
    }

    const getAssessment = async () => {
        const data = await fetchToAPI(`https://app.toiyabe.us/api/assessments/${id}`);
        setAssQuestions(data.data.assessment_question);
        setAssConditions(data.data.assessment_condition);
    }

    const getAssessmentQuestions = async () => {
        const data = await fetchToAPI(`https://app.toiyabe.us/api/assessments/${id}`);
        setAssQuestions(data.data.assessment_question);
    };

    const getAssessmentConditions = async () => {
        const data = await fetchToAPI(`https://app.toiyabe.us/api/assessments/${id}`);
        setAssConditions(data.data.assessment_condition);
    };

    useEffect(() => {
        getAssessment();
    }, []);

    const handleEditQuestion = async (values) => {
        console.log("handleEditQuestion", values.answers);

        const result = await putApplicationJson(`https://app.toiyabe.us/api/admin/assessment/question/${questionIdToEdit}`, {
            question: values.question,
            answer: values.answers,
        });
        alert(result.data.message);
        getAssessmentQuestions();
        setOpenEdit(false);
    };

    const handleDeleteQuestion = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/assessment/question/${questionIdToEdit}`);
        getAssessmentQuestions();
        setOpenEdit(false);
    };

    const handleAddCondition = async (values) => {
        const result = await postApplicationJson(`https://app.toiyabe.us/api/admin/assessment/${id}/condition`, {
            scoring: values.score,
            show: values.show,
            display: values.display
        });
        alert(result.data.message);
        getAssessmentConditions();
        setOpenCondition(false);
    }

    const handleEditCondition = async (values) => {
        const result = await putApplicationJson(`https://app.toiyabe.us/api/admin/assessment/condition/${conditionToEdit.id}`, {
            scoring: values.score,
            show: values.show,
            display: values.display
        });
        alert(result.data.message);
        getAssessmentConditions();
        setOpenEditCondition(false);
    }

    const handleDeleteCondition = async () => {
        await deleteItem(`https://app.toiyabe.us/api/admin/assessment/condition/${conditionToEdit.id}`);
        getAssessmentQuestions();
        setOpenEditCondition(false);
    };

    return (
        <div className="page">
            <div className="resiliancyHeader">
                <h3 style={{fontSize: '28px', fontWeight: 'bold'}}>{title}</h3>
                <button onClick={() => setOpen(true)}
                style={{
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                    backgroundColor: '#3B7D7D',
                }}>
                    Add +
                </button>
            </div>
            <div className="resiliancy-table">
                <table>
                    {assQuestions.length > 0 && assQuestions.map((questionObj) => {
                        return (
                            <tr>
                                <td>
                                    <div style={{marginLeft: '16px', fontSize: '12pt', cursor: 'pointer'}} onClick={() => fetchEditQuestion(questionObj)}>
                                        {questionObj.question}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>
            <div className="resiliancyHeader">
                <h3 style={{fontSize: '22pt'}}>Conditions</h3>
                <button onClick={() => setOpenCondition(true)}
                style={{
                    color: '#FFF',
                    backgroundColor: '#3B7D7D',
                    fontSize: '12pt',
                    fontWeight: 'bold',
                    height: '48px',
                    border: '10px solid #3B7D7D',
                    backgroundColor: '#3B7D7D',
                }}>
                    Add Condition +
                </button>
            </div>
            <div className="resiliancy-table">
                <table>
                    { assConditions && assConditions.length > 0 &&
                        assConditions.map((obj) => {
                            return (
                                <tr>
                                    <td>
                                    <div style={{marginLeft: '16px', fontSize: '12pt', cursor: 'pointer'}} onClick={() => fetchEditCondition(obj)}>
                                        {`Label: ${obj.show ?? "[Label not set]"} | Below ${obj.scoring} Correct Answers | Display: ${obj.display}`}
                                    </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
            {/* Add Question */}
            <Formik
            initialValues={{
                question: '',
                answers: [],
            }}
            onSubmit={(values) => handleAddQuestion(values)}
            validationSchema={questionValidation}>
            {(formikProps) => (
                <Modal
                show={open}
                onHide={() => { formikProps.resetForm(); setOpen(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Question
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('question')}
                                onBlur={formikProps.handleBlur('question')}
                                value={formikProps.values.question}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Question text"
                                multiline
                            />
                            <ErrorMessage name="question" component="div" className="text-danger" />
                        </div>
                        {/* Answers Block */}
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{flexDirection: 'row', color:"#000", fontSize:16, fontWeight: 'bold', }}
                            >
                                Answers
                            </InputLabel>
                            <p style={{position: 'relative', display: 'flex', justifyContent: 'center', fontSize: '12pt'}}>
                                * Tick the checkbox of the answer(s) if they are correct.
                            </p>
                            <FieldArray
                                name="answers"
                                render={arrayHelpers => (
                                <>
                                { formikProps.values.answers.length > 0 && formikProps.values.answers.map( (ans, index) => (
                                <div key={`answers_${index}`}>
                                    <div style={{paddingBottom:  '16px'}}>
                                        <InputLabel
                                        InputLabelProps={{shrink: false, color: '#FFF'}}
                                        style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Answer
                                    </InputLabel>
                                    <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center'}}>
                                        <TextField
                                            onChange={formikProps.handleChange(`answers.${index}.answer`)}
                                            onBlur={formikProps.handleBlur(`answers.${index}.answer`)}
                                            value={formikProps.values.answers[index].answer}
                                            size="small"
                                            style={{
                                                width: '60%',
                                                position: 'relative',
                                            }}
                                            placeholder="Enter Answer text"
                                        />
                                        <FormCheck
                                            style={{marginLeft: '16px', marginRight: '16px', alignSelf: 'center'}}
                                            type="checkbox"
                                            name={`answers.${index}.correct`}
                                            onChange={e =>  {
                                                formikProps.setFieldValue(`answers.${index}.correct`, e.target.checked);
                                            }}
                                            value={formikProps.values.answers[index].correct}
                                            checked={formikProps.values.answers[index].correct}
                                        />
                                        <button style={{flexDirection: 'row', justifySelf: 'center', width: '20%'}} type="button" className="delete-word-button" onClick={() => arrayHelpers.remove(index)}>
                                            Remove
                                        </button>
                                    </div>
                                    <ErrorMessage name={`answers[${index}].answer`} component="div" className="text-danger" />
                                    </div>
                                    <hr/>
                                </div>
                                ))}
                                <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={() => arrayHelpers.push({id: null, answer: '', correct: false})}>
                                    Add Answer
                                </button>
                                </>
                            )}/>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Assessment
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>

            {/* Edit Question */}
            <Formik
            initialValues={{
                question: assQuestionToEdit,
                answers: assAnswersToEdit,
            }}
            enableReinitialize
            onSubmit={(values) => handleEditQuestion(values)}
            validationSchema={questionValidation}>
            {(formikProps) => (
                <Modal
                show={openEdit}
                onHide={() => { formikProps.resetForm(); setOpenEdit(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Question
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('question')}
                                onBlur={formikProps.handleBlur('question')}
                                value={formikProps.values.question}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Question text"
                                multiline
                            />
                            <ErrorMessage name="question" component="div" className="text-danger" />
                        </div>
                        {/* Answers Block */}
                        <div style={{paddingTop: '16px', paddingBottom: '16px'}}>
                            <InputLabel
                                InputLabelProps={{shrink: false, color: '#FFF'}}
                                style={{flexDirection: 'row', color:"#000", fontSize:16, fontWeight: 'bold', }}
                            >
                                Answers
                            </InputLabel>
                            <p style={{position: 'relative', display: 'flex', justifyContent: 'center', fontSize: '12pt'}}>
                                * Tick the checkbox of the answer(s) if they are correct.
                            </p>
                            <FieldArray
                                name="answers"
                                render={arrayHelpers => (
                                <>
                                { formikProps.values.answers.length > 0 && formikProps.values.answers.map( (ans, index) => (
                                <div key={`answers_${index}`}>
                                    <div style={{paddingBottom:  '16px'}}>
                                        <InputLabel
                                        InputLabelProps={{shrink: false, color: '#FFF'}}
                                        style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative', }}
                                    >
                                        Answer
                                    </InputLabel>
                                    <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center'}}>
                                        <TextField
                                            onChange={formikProps.handleChange(`answers.${index}.answer`)}
                                            onBlur={formikProps.handleBlur(`answers.${index}.answer`)}
                                            value={formikProps.values.answers[index].answer}
                                            size="small"
                                            style={{
                                                width: '60%',
                                                position: 'relative',
                                            }}
                                            placeholder="Enter Answer text"
                                        />
                                        <FormCheck
                                            style={{marginLeft: '16px', marginRight: '16px', alignSelf: 'center'}}
                                            type="checkbox"
                                            name={`answers.${index}.correct`}
                                            onChange={e =>  {
                                                formikProps.setFieldValue(`answers.${index}.correct`, e.target.checked);
                                            }}
                                            value={formikProps.values.answers[index].correct}
                                            checked={formikProps.values.answers[index].correct}
                                        />
                                        <button style={{flexDirection: 'row', justifySelf: 'center', width: '20%'}} type="button" className="delete-word-button" onClick={() => arrayHelpers.remove(index)}>
                                            Remove
                                        </button>
                                    </div>
                                    <ErrorMessage name={`answers[${index}].answer`} component="div" className="text-danger" />
                                    </div>
                                    <hr/>
                                </div>
                                ))}
                                <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={() => arrayHelpers.push({id: null, answer: '', correct: false})}>
                                    Add Answer
                                </button>
                                </>
                            )}/>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <ErrorMessage name={`answers`} component="div" className="text-danger" />
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Assessment
                        </button>
                        <button type="button" style={{width: '340px', }} className="delete-tribe-button" onClick={handleDeleteQuestion}>
                            Delete Assessment
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>

            {/* Add Condition */}
            <Formik
            initialValues={{
                condition: 'Under certain %',
                score: 0,
                show: '',
                display: '',
            }}
            onSubmit={(values) => handleAddCondition(values)}
            validationSchema={conditionValidation}>
            {(formikProps) => (
                <Modal
                show={openCondition}
                onHide={() => { formikProps.resetForm(); setOpenCondition(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Condition</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Show Label
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('show')}
                                onBlur={formikProps.handleBlur('show')}
                                value={formikProps.values.show}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Label for condition"
                                multiline
                            />
                            <ErrorMessage name="show" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Correct %
                            </InputLabel>
                            <Select
                                value={formikProps.values.condition}
                                onChange={formikProps.handleChange}
                                placeholder="Select Condition"
                                style={{minWidth: '100%'}}
                                name="condition"
                            >
                                <MenuItem key={"ConditionSelect_0"} value={"Under certain %"}>Under certain %</MenuItem>
                            </Select>
                            <ErrorMessage name="condition" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Scoring
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('score')}
                                onBlur={formikProps.handleBlur('score')}
                                value={formikProps.values.score}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Score number"
                                multiline
                            />
                            <ErrorMessage name="score" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Display
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('display')}
                                onBlur={formikProps.handleBlur('display')}
                                value={formikProps.values.display}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Text to Display"
                                multiline
                            />
                            <ErrorMessage name="display" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Condition
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>

            {/* Edit Condition */}

            <Formik
            initialValues={{
                condition: 'Under certain %',
                score: conditionToEdit.scoring ?? 0,
                show: conditionToEdit.show ?? "",
                display: conditionToEdit.display ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => handleEditCondition(values)}
            validationSchema={conditionValidation}>
            {(formikProps) => (
                <Modal
                show={openEditCondition}
                onHide={() => { formikProps.resetForm(); setOpenEditCondition(false)}}
                backdrop="static"
                keyboard={false}
                >
                    <form key="addResiliencyForm" onSubmit={e => { e.preventDefault(); formikProps.handleSubmit()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Condition</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Show Label
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('show')}
                                onBlur={formikProps.handleBlur('show')}
                                value={formikProps.values.show}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Label for condition"
                                multiline
                            />
                            <ErrorMessage name="show" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Correct %
                            </InputLabel>
                            <Select
                                value={formikProps.values.condition}
                                onChange={formikProps.handleChange}
                                placeholder="Select Condition"
                                style={{minWidth: '100%'}}
                                name="condition"
                            >
                                <MenuItem key={"ConditionSelect_0"} value={"Under certain %"}>Under certain %</MenuItem>
                            </Select>
                            <ErrorMessage name="condition" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Scoring
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('score')}
                                onBlur={formikProps.handleBlur('score')}
                                value={formikProps.values.score}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Score number"
                                multiline
                            />
                            <ErrorMessage name="score" component="div" className="text-danger" />
                        </div>
                        <div style={{paddingTop: '16px' }}>
                            <InputLabel
                                style={{color:"#000", fontSize:14, fontWeight: 'bold', position: 'relative',}}
                            >
                                Display
                            </InputLabel>
                            <TextField
                                onChange={formikProps.handleChange('display')}
                                onBlur={formikProps.handleBlur('display')}
                                value={formikProps.values.display}
                                size="small"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                }}
                                placeholder="Enter Text to Display"
                                multiline
                            />
                            <ErrorMessage name="display" component="div" className="text-danger" />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <button type="button" style={{width: '340px', }} className="add-tribe-button" onClick={formikProps.handleSubmit}>
                            Save Condition
                        </button>
                        <button type="button" style={{width: '340px', }} className="delete-tribe-button" onClick={handleDeleteCondition}>
                            Delete Condition
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            )}
            </Formik>
        </div>
    )
}
