export default class LocalStorageService {
    static Keys = {
        USER_TOKEN: "USER_TOKEN",
        USER_NAME: "USER_NAME",
    }

    static fetchToken = () => {
        return localStorage.getItem(LocalStorageService.Keys.USER_TOKEN) || '';
    }

    static setToken = (token) => {
        return localStorage.setItem(LocalStorageService.Keys.USER_TOKEN, token);
    }

    static clearToken = () => {
        localStorage.removeItem(LocalStorageService.Keys.USER_TOKEN);
    } 
}