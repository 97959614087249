import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import logo from '../../toiyabe-logo-only.png';
import { Button, TextField, InputAdornment, IconButton, Input } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Axios from 'axios';
import './login.css'
import LocalStorageService from '../../Services/LocalStorageService';
import { useDispatch } from 'react-redux';
import ActionTypes from '../../Redux/ActionTypes';

export default function Login({ auth }){
    const [values, setValues] = useState({
        email: '',
        validEmail: true,
        password: '',
        showPassword: false,
        error: '',
        attempts: 2
    });

    const dispatch = useDispatch();

    const handleLogIn = () => {
        if(values.attempts === 0 && values.error.length){
            setValues({ ...values, attempts: 2, error: 'You are locked out for 60 minutes. Try again later.' });
        } else if(values.attempts > 0){
            signUp();
        }
    };
    
    const signUp = async () => {
        try {
            const result = await Axios({
                method: "POST",
                url: 'https://app.toiyabe.us/api/auth/login',
                data: {
                    email: values.email,
                    password: values.password,
                },
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            //check to see if there is a user or message that says that they are logged in
            if(result.data.access_token){
                let token = result.data.access_token;
                LocalStorageService.setToken(token);
                dispatch({type: ActionTypes.USER_LOGIN, payload: {token: token, email: values.email}});
            } else {
                setValues({ ...values, attempts: values.attempts - 1, error: `Login credentials invalid. ${values.attempts} more failed login attempts will lock further attempts for 60 minutes.`});
            }
        } catch (e) {
            if(e.response.status === 401) {
                setValues({ ...values, attempts: values.attempts - 1, error: `Login credentials invalid. ${values.attempts} more failed login attempts will lock further attempts for 60 minutes.`});
            } else {
                setValues({ ...values, error: `Something went wrong. Try again later.`});
            }
        }
    };

    const validateEmail = () => {
        if(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)){
            setValues({ ...values, validEmail: true})
        }else {
            setValues({ ...values, validEmail: false})
        }
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
        {!auth ?
        <div className="loginContainer">
            <img className='logo' src={logo} alt="Toiyabe Logo"/>
            <h1 className="header" style={{fontWeight: 300, fontFamily: 'sans-serif'}}>Log In</h1>
            {values.error.length > 1 ? 
                <p className="login-credentials-in" style={{color: '#B21313'}}>
                    {values.error}
                </p>
                : null} 
            <div className="formContainer">
                <form className='first-form' noValidate autoComplete="off">
                    <p className="email-address-input-header">Email Address</p>
                    <TextField 
                    onBlur={() => validateEmail()} 
                    onFocus={() => setValues({ ...values, validEmail: true})} 
                    style={{width: '100%'}} 
                    onChange={handleChange('email')} 
                    placeholder="Enter your email address" 
                    />
                    {values.validEmail ?
                        null
                    :
                        <p style={{color: 'red'}} id="component-error-text">Error</p>
                    }
                    <p className="email-address-input-header">Password</p>
                    <Input
                        type={values.showPassword || values.password === '' ? 'text' : 'password'}
                        value={values.password}
                        placeholder="Enter your password"
                        onChange={handleChange('password')}
                        style={{width: '100%'}} 
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </form>
                <Button onClick={handleLogIn} variant="contained" style={{color: "#FFF", backgroundColor: '#3B7D7D', width: '100%', marginTop: '60px' }} >
                    Log In
                </Button>   
            </div>           
        </div>
        :
        <Redirect to="/dashboard" push />
        }
        </>
    )
}