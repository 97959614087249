import Axios, { AxiosResponse } from 'axios';
import LocalStorageService from '../../Services/LocalStorageService';


function getApiKey() {
    return `Bearer ${LocalStorageService.fetchToken()}`;
}

async function handleResponseStatus(response: AxiosResponse<any>) {
    if(response.status === 401) {
        console.log('Inside 401', response);
        LocalStorageService.clearToken();
    }
}

export async function fetchToAPI(url){
    try {
        const response = await Axios({
            url: url,
            method: 'GET',
            headers: {
                "Authorization": getApiKey()
            }
        })
        return response;
    } catch(e) {
        handleResponseStatus(e.response);
        return;
    }
};

export async function postJsonData(url, obj){
    const response = await Axios({
        method: 'POST',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            "Content-Type": "application/json"
        }
    });
    handleResponseStatus(response);
    return response;
} 

export async function updateCulture(url, obj){
    const response = await Axios({
        method: 'POST',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            "Content-Type": "application/json"
        }
    });
    handleResponseStatus(response);
    return response;
} 

export async function updatePhoto(url, obj){
    const response = await Axios({
        method: 'POST',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            "Content-Type": "multipart/form-data"
        }
    });
    handleResponseStatus(response);
    return response;
} 

export async function addCultureResource(url, obj){
    const response = await Axios({
        method: 'POST',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            'content-type': 'application/x-www-form-urlencoded',
        }
    });
    handleResponseStatus(response);
    return response;
} 

export async function postApplicationJson(url, obj){
    const response = await Axios({
        method: 'POST',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            'Content-type': 'application/json'
        }
    });
    handleResponseStatus(response);
    return response;
} 


export async function patchApplicationJson(url, obj){
    const response = await Axios({
        method: 'PATCH',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            'Content-type': 'application/json'
        }
    });
    handleResponseStatus(response);
    return response;
} 

export async function putMultiPart(url, obj){
    const response = await Axios({
        method: 'POST',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            'Content-type': 'multipart/form-data'
        }
    });
    handleResponseStatus(response);
    return response;
} 

export async function putApplicationJson(url, obj){
    const response = await Axios({
        method: 'PUT',
        url: url,
        data: obj,
        headers: {
            "Authorization": getApiKey(),
            'Content-type': 'application/json'
        }
    });
    handleResponseStatus(response);
    return response;
} 

export async function deleteItem(url){
    let response = await Axios({
        method: 'DELETE',
        url: url,
        headers: {
            "Authorization": getApiKey()
        }
    });
    handleResponseStatus(response);
    return response;
} 